import React, { useState } from 'react'
import {Link, useHistory} from 'react-router-dom'
import MyAccount from './MyAccount'
import MyAddress from './MyAddress'

function HomeSetting() {
    const history = useHistory();

    return (
        
            <div className="col-md-4 p-0 hide-mobile">
                <Link to="/my_order" className={`${history.location.pathname === "/my_order" ? "active" : ""} side-link`}>My Orders</Link> 
                <Link to="/my_address" className={`${history.location.pathname === "/my_address" ? "active" : ""} side-link`}>My addresses</Link>
                <Link to ="/my_account" className={`${history.location.pathname === "/my_account" ? "active" : ""} side-link`}>My account</Link> 
                <Link to="/help" className={`${history.location.pathname === "/help" ? "active" : ""} side-link`}>Help</Link>
                <Link to="/termsandconditions" className={`${history.location.pathname === "/termsandconditions" ? "active" : ""} side-link`}>Terms and conditions</Link> 
                <Link to="/privacy_policy" className={`${history.location.pathname === "/privacy_policy" ? "active" : ""} side-link`}>Privacy policy</Link>
                <Link  to="/Logout" className="side-link">Log out</Link>
            </div>
        
        //     <div>
        //     <div className="container py-3">
        //         <nav aria-label="breadcrumb">
        //             <ol className="breadcrumb">
        //                 <li className="breadcrumb-item"><a href="#">Home</a></li>
        //             <li className="breadcrumb-item active" aria-current="page">Settings</li>
        //             </ol>
        //         </nav>
        //     </div>
        //     <div className="container mb-4">
        //         <div className="row mb-4">
        //             <div className="col-12 page-head-with-more">
        //                 <h2 className="heading-colored fw-bold text-uppercase m-0">SETTINGS</h2>
        //             </div>
        //         </div>
        //         <div className="row">
        //             <div className="col-md-4 p-0 hide-mobile">
        //                 <a href="/" className="side-link">My Orders</a> 
        //                 <a href="/" className="side-link">My addresses</a>
        //                 <a to ="/my_account" className="side-link active">My account</a> 
        //                 <a href="/" className="side-link">Help</a>
        //                 <a href="/" className="side-link">Terms and conditions</a> 
        //                 <a href="/" className="side-link">Privacy policy</a>
        //                 <a  to="/Logout" className="side-link">Log out</a>
        //             </div>
        //             <div className="col-md-8">
        //                 <form className="gray-inout px-3">
        //                         <h5 className="m-0 fw-bold">My Account</h5>
        //                         <div className="mb-3 mt-4">
        //                             <label for="firstname" className="form-label">First name</label>
        //                             <input type="text" className="form-control" placeholder="Type First Name" id="firstname" />
        //                         </div>
        //                         <div className="mb-3">
        //                             <label for="lastname" className="form-label">Last name</label>
        //                             <input type="text" className="form-control" placeholder="Type Last Name" id="lastname" />
        //                         </div>
        //                         <div className="mb-3">
        //                             <label for="exampleFormControlInput1" className="form-label">Email</label>
        //                             <input type="email" className="form-control" placeholder="name@emailaddress.com" id="exampleFormControlInput1" />
        //                         </div>
        //                         <div className="mb-3">
        //                             <label for="phonenumber" className="form-label">Phone number</label>
        //                             <div className="input-group with-bg">
        //                                 <select className="form-select input-group-drop me-2 rounded-3" id="countrySelectDrop">
        //                                 <option selected>+1</option>
        //                                 <option value="1">+2</option>
        //                                 <option value="2">+3</option>
        //                                 <option value="3">+4</option>
        //                                 </select>
        //                                 <input type="text" className="form-control rounded-3" placeholder="Phone number" id="phonenumber" />
        //                             </div>
        //                         </div>
        //                         <div className="mb-3">
        //                             <label for="OldPassword" className="form-label">Old password</label>
        //                             <div className="input-group">
        //                                 <input type="text" className="form-control" id="OldPassword" placeholder="********" aria-label="OldPassword" aria-describedby="basic-addon1" />
        //                                 <span className="input-group-text" id="basic-addon1"><i className="fs-5 bi-eye" id="toggleOldPassword"></i></span> 
        //                             </div>
        //                         </div>
        //                         <div className="mb-3">
        //                             <label for="NewPassword" className="form-label">New password</label>
        //                                 <div className="input-group">
        //                                     <input type="text" className="form-control" id="NewPassword" placeholder="********" aria-label="newpassword" aria-describedby="basic-addon1" />
        //                                     <span className="input-group-text" id="basic-addon1"><i className="fs-5 bi-eye" id="toggleNewPassword"></i></span> 
        //                                 </div>
        //                             </div>
        //                         <div className="mb-2">
        //                             <label className="form-label">Which cultural cuisines are you most interested in?</label>
        //                         </div>
        //                         <div className="row cuisines-radio">
        //                         <div className="col">
        //                             <div className="owl-carousel owl-theme" id="carousel-example">
        //                                     <div className="item">
        //                                         <label className="select-cultural">
        //                                             <input type="checkbox" value="0" />
        //                                             <img src="images/product01.png" width="75" alt="cuisines" className="rounded-2 w-100" />
        //                                             <p>West African</p>
        //                                         </label>
        //                                     </div>
        //                                     <div className="item">
        //                                         <label className="select-cultural">
        //                                         <input type="checkbox" value="0" />
        //                                         <img src="images/product02.png" width="75" alt="cuisines" className="rounded-2 w-100" />
        //                                         <p>South African</p>
        //                                         </label>
        //                                     </div>
        //                                     <div className="item">
        //                                         <label className="select-cultural">
        //                                             <input type="checkbox" value="0" />
        //                                             <img src="images/product03.png" width="75" alt="cuisines" className="rounded-2 w-100" /> 
        //                                             <p>East African</p>
        //                                         </label>
        //                                     </div>
        //                                     <div className="item">
        //                                         <label className="select-cultural">
        //                                             <input type="checkbox" value="0" />
        //                                             <img src="images/product04.png" width="75" alt="cuisines" className="rounded-2 w-100" />
        //                                             <p>Jamaican</p>
        //                                         </label>
        //                                     </div>
        //                                     <div className="item">
        //                                         <label className="select-cultural">
        //                                             <input type="checkbox" value="0" />
        //                                             <img src="images/product05.png" width="75" alt="cuisines" className="rounded-2 w-100"/>
        //                                             <p>West African</p>
        //                                         </label>
        //                                     </div>
        //                                     <div className="item">
        //                                         <label className="select-cultural">
        //                                             <input type="checkbox" value="0" />
        //                                             <img src="images/product06.png" width="75" alt="cuisines" className="rounded-2 w-100" />
        //                                             <p>South African</p>
        //                                         </label>
        //                                     </div>
        //                                     <div className="item">
        //                                         <label className="select-cultural">
        //                                             <input type="checkbox" value="0" />
        //                                             <img src="images/product01.png" width="75" alt="cuisines" className="rounded-2 w-100" />
        //                                             <p>East African</p>
        //                                         </label>
        //                                     </div>
        //                                     <div className="item">
        //                                         <label className="select-cultural">
        //                                             <input type="checkbox" value="0" />
        //                                             <img src="images/product02.png" width="75" alt="cuisines" className="rounded-2 w-100" />
        //                                             <p>Jamaican</p>
        //                                         </label>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>       
        //                     <div className="my-3">
        //                         <button type="submit" className="btn btn-dark btn-lg mb-3 w-100 text-uppercase">Save</button>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default HomeSetting

import api from "../../config/ApiConfig";
import { Apis } from "../../config";
import axios from "axios";

// import { NotificationManager } from 'react-notifications';

const addOrUpdateCart = async (quantity,variation_id) => {
  let data = { quantity: `${quantity}`, variation_id: `${variation_id}` };
  
  try {
      let result = await api.post(Apis.AddOrUpdateCart,data);
      if (result) {
          // NotificationManager.error(result.data.error,//axios//api);
          return result;
      }
      // return result;
  } catch (error) {
      console.log(error);
      return null;
  }
};

export default addOrUpdateCart;

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const GET_TOTALS = "GET_TOTALS";

export const CART_COUNT = "CART_COUNT";

//////////////////
export const USER_DATA = 'USER_DATA';


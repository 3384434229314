import React, { useEffect, useState } from "react";
import axios from "axios";
import ServiceModal from "./comman/ServiceModal";
import { Link } from "react-router-dom";
import { GetPopularProduct, GetCategoryList , GetListOfAllThing} from "../services";

import AddtoCart from "./comman/AddToCart";
import Category from "./category/Category";
import Product from "./product/Product";
import ListOfAllThings from "./product/ListOfAllThings";

const Home = (props) => {
  const [allCategories, setAllCategory] = useState([]);
  const [listOfAllThing, setlistOfAllThing] = useState([]);

  const [sellerId, setSellerId] = useState(44);
  useEffect(() => {
    
    async function getAllCategories() {
      const res = await GetCategoryList.getCategoryList(sellerId);
      if (res) {
        console.log("category",res)
        setAllCategory(res.data.data.category);
        await localStorage.setItem(
          "allCategories",
          JSON.stringify(res.data.data.category)
        );
      } else {
        console.log("error");
      }
    }

    getAllCategories();

    async function listOfAllThings() {
      const res = await GetListOfAllThing.getListOfAllThings();
      if (res) {
        // console.log("setlistOfAllThing",res)
        setlistOfAllThing(res.data.data.products);
        await localStorage.setItem(
          "listOfAllThings",
          JSON.stringify(res.data.data.products)
        );
      } else {
        console.log("error");
      }
    }

    listOfAllThings();
  }, []);
  //console.log("listOfAllThing",listOfAllThing)
  return (
    <div>
      {/* <div className="top-bar-promo">Promo code prompt</div> */}
      {/* <button className="top-bar-promo" onClick={getList}>Promo code prompt</button> */}

      <section className="home-banner">
        <div className="container">
          <div className="row align-items-center div-fullscreen">
            <div className="col-md-6 py-5 home-banner-left">
              <h1 className="migra-font">
                Groceries from your culture, delivered.
              </h1>
              <h5 className="fw-normal my-4 text-muted">
                African & Caribbean groceries straight to your door - no fuss,
                no and quality guaranteed. Order before 3pm for next day
                delivery.
              </h5>
              {/* <button
                type="button"
                className="btn btn-default text-uppercase btn-lg w-75 mt-3"
              > */}
              <Link
                className="btn btn-default text-uppercase btn-lg w-75 mt-3"
                to="/culture"
              >
                Shop by culture
              </Link>
              {/* </button> */}
            </div>
          </div>
        </div>
      </section>
      <Product/>
      <Category allCategories={allCategories} />
      <section className="say-goodbye text-white text-center">
        <div className="devider-top-white"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto text-center">
              <h1 className="migra-font my-5">
                Say goodbye to long trips to the market!
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4">
              <i
                className="bi bi-globe fs-1"
                // style={{color:rgba(255, 255, 255, 0.4)}}
              ></i>
              <h6 className="fw-bold my-4">
                Ethnic products from all over the world
              </h6>
              <p>
                Shop from all over Africa; from Nigeria to Kenya or deep into
                the Caribbean; in Trinidad & Tobago or Jamaica - we cater to
                all!
              </p>
            </div>
            <div className="col-md-4 mb-4">
              <i
                className="bi bi-geo-alt-fill fs-1"
                // style={{color:rgba(255, 255, 255, 0.4)}}
              ></i>
              <h6 className="fw-bold my-4">
                Delivery across entire UK & same day in London
              </h6>
              <p>
                We deliver next day across the UK and same day within London, so
                you can cook whatever pops into your head that day
              </p>
            </div>
            <div className="col-md-4 mb-4">
              <i
                className="bi bi-emoji-laughing fs-1"
                // style={{color:rgba(255, 255, 255, 0.4)}}
              ></i>
              <h6 className="fw-bold my-4">
                Simple to use, bespoke to your culture
              </h6>
              <p>
                With 1000s of products within a variety of categories, you'll
                always find what you need, whether it's green plantain or ata
                rodo
              </p>
            </div>
          </div>
        </div>
        <div className="devider-bottom-white"></div>
      </section>
      <ListOfAllThings listOfAllThing = {listOfAllThing} />      
      <section className="order-again d-none">
        <div className="container py-5">
          <div className="row mb-3">
            <div className="col-12 page-head-with-more">
              <h2 className="fw-bold text-uppercase m-0">
                Order again?
                <span className="text-sub-head">Your favourite picks</span>
              </h2>
              <button type="button" className="btn btn-link view-more-but">
                More <i className="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-6 g-4 product-grid-6">
            <div className="col-6 col-md-2">
              <div className="card">
                {" "}
                <img
                  src="images/product01.png"
                  className="card-img-top"
                  alt="Product Name"
                />
                <div className="card-body">
                  <p className="card-title">
                    <a href=" ">Product Name Two Different Lines</a>
                  </p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col">
                      <div className="grid-prod-price">£0.00</div>
                      <div className="grid-prod-qty">/kg</div>
                    </div>
                    <div className="col p-0 text-right">
                      <button className="btn btn-default btn-sm">Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className="card">
                {" "}
                <img
                  src="images/product02.png"
                  className="card-img-top"
                  alt="Product Name"
                />
                <div className="card-body">
                  <p className="card-title">
                    <a href=" ">Product Name Two Different Lines</a>
                  </p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col">
                      <div className="grid-prod-price">£0.00</div>
                      <div className="grid-prod-qty">/kg</div>
                    </div>
                    <div className="col p-0 text-right">
                      <button className="btn btn-default btn-sm">Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className="card">
                {" "}
                <img
                  src="images/product03.png"
                  className="card-img-top"
                  alt="Product Name"
                />
                <div className="card-body">
                  <p className="card-title">
                    <a href=" ">Product Name Two Different Lines</a>
                  </p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col">
                      <div className="grid-prod-price">£0.00</div>
                      <div className="grid-prod-qty">/kg</div>
                    </div>
                    <div className="col p-0">
                      <div className="input-group mb-3 pro-amt">
                        <button type="button" className="input-group-text">
                          <i className="bi bi-dash"></i>
                        </button>
                        <input type="text" className="form-control" value="1" />
                        <button type="button" className="input-group-text">
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className="card">
                {" "}
                <img
                  src="images/product04.png"
                  className="card-img-top"
                  alt="Product Name"
                />
                <div className="card-body">
                  <p className="card-title">
                    <a href=" ">Product Name Two Different Lines</a>
                  </p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col">
                      <div className="grid-prod-price">£0.00</div>
                      <div className="grid-prod-qty">/kg</div>
                    </div>
                    <div className="col p-0">
                      <div className="input-group mb-3 pro-amt">
                        <button type="button" className="input-group-text">
                          <i className="bi bi-dash"></i>
                        </button>
                        <input type="text" className="form-control" value="1" />
                        <button type="button" className="input-group-text">
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className="card">
                {" "}
                <img
                  src="images/product05.png"
                  className="card-img-top"
                  alt="Product Name"
                />
                <div className="card-body">
                  <p className="card-title">
                    <a href=" ">Product Name Two Different Lines</a>
                  </p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col">
                      <div className="grid-prod-price">£0.00</div>
                      <div className="grid-prod-qty">/kg</div>
                    </div>
                    <div className="col p-0 text-right">
                      <button className="btn btn-default btn-sm">Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className="card">
                {" "}
                <img
                  src="images/product06.png"
                  className="card-img-top"
                  alt="Product Name"
                />
                <div className="card-body">
                  <p className="card-title">
                    <a href=" ">Product Name Two Different Lines</a>
                  </p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col">
                      <div className="grid-prod-price">£0.00</div>
                      <div className="grid-prod-qty">/kg</div>
                    </div>
                    <div className="col p-0 text-right">
                      <button className="btn btn-default btn-sm">Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Modal --> */}
      {/* <ServiceModal /> */}
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GetProductsOfSubCategory } from "../../services";
import AddtoCart from "../comman/AddToCart";
import { connect, useSelector, useDispatch } from "react-redux";

const CategoryDetails = (props) => {
  let { cartItems } = props;

  let location = useLocation();
  const [allCategories, setAllCategories] = useState(
    location.state.category ? location.state.category : null
  );
  const [subCategories, setSubCategories] = useState(
    location.state.subCategory ? location.state.subCategory : null
  );
  const [productOfSubCategory, setProductOfSubCategory] = useState({});
  const [selected, setSelected] = useState(0);
  // const[showPath,setShowPath] = useState({category:"category",subCategory:"subCategory"});
  const [showPath, setShowPath] = useState(
    location.state.path ? location.state.path : null
  );
  const postsPerPage = 6;
  let arrayForHoldingPosts = [];
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(6);
  const [loadAll, setLoadAll] = useState(0);
  const [checked, setChecked] = useState(
    location.state.index ? location.state.index : null
  );
  useEffect(() => {
    loopWithSlice(0, postsPerPage);
    getProduct({
      id: subCategories[0].id,
      parent_id: subCategories[0].parent_id,
    });
  }, []);

  const getProduct = (item, index = 0) => {
    let { id, parent_id } = item;
    setSelected(index);

    async function productsOfSubCategory() {
      const res = await GetProductsOfSubCategory.getProductsOfSubCategory(
        id,
        parent_id
      );
      if (res) {
        console.log(res);
        setProductOfSubCategory(res.data.data.products.data);
        console.log("GetProductsOfSubCategory", res.data.data.products.data);
        // setPopularProduct(res.data.data.popular_products);
        // await localStorage.setItem("popularProducts", JSON.stringify(res.data));
      } else {
        console.log("error");
      }
    }
    productsOfSubCategory();
  };

  const getCategory = (item) => {
    setSubCategories(item.sub_categories);
    setSelected(0);
    getProduct({
      id: item.sub_categories[0].id,
      parent_id: item.sub_categories[0].parent_id,
    });
    console.log("item", item);
    setShowPath({
      category: item.name,
      subCategory: item.sub_categories[0].name,
    });
  };

  // ----------------load more ---------------
  const loopWithSlice = (start, end) => {
    const slicedPosts = allCategories.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow([...postsToShow, ...arrayForHoldingPosts]);
  };

  const handleShowMorePosts = () => {
    setLoadAll(1);

    loopWithSlice(next, next + allCategories.length);
    setNext(next + postsPerPage);
  };
  // ----------------load more ---------------

  const onChange = (index) => {
    setChecked(index);
  };

  return (
    <div>
      <section className="section-breadcrumb">
        <div className="container py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {showPath.category}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {showPath.subCategory}
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="cuisines-list">
        <div className="container">
          <div className="row">
            <div className="col-12 page-head-with-more">
              <h2 className="heading-colored fw-bold text-uppercase my-3">
                Categories
              </h2>
            </div>
          </div>

          <div className="row row-cols-3 row-cols-md-6 g-4 wrap-label-row">
            {postsToShow.length > 0 &&
              postsToShow.map((category, index) => (
                <div
                  className="col"
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getCategory(category);
                    setChecked(index);
                  }}
                >
                  <img
                    src={category.image}
                    width="100"
                    height="100"
                    alt="cuisines"
                    className="rounded-4 w-100"
                  />
                  <p className="wrap-label">{category.name}</p>
                </div>
              ))}
          </div>

          <div className="row">
            <div className="col-12 mt-4 text-center">
              {loadAll === 1 ? null : (
                <button
                  onClick={handleShowMorePosts}
                  type="button"
                  className="btn btn-outline-light px-5"
                >
                  Load all
                </button>
              )}
            </div>
          </div>
          {/* callapi */}

          <div className="row mt-5">
            <div className="col-12">
              <div className="vertical-scroll">
                {subCategories.length > 0 &&
                  subCategories.map((item, index) => (
                    <button
                      type="button"
                      key={index}
                      onClick={() => {
                        getProduct(item, index);
                        setShowPath({
                          category: showPath.category,
                          subCategory: item.name,
                        });
                      }}
                      className={
                        index === selected
                          ? "btn btn-brown me-4"
                          : "btn btn-light me-4"
                      }
                    >
                      {item.name}
                    </button>
                  ))}
              </div>
            </div>
          </div>
          {/* callapi */}
        </div>
      </section>

      <section className="section-products mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="border-bottom border-2"></div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 side-bar-filter hide-mobile">
              <h6 className="text-uppercase border-bottom border-2 text-muted-light py-3">
                Filter By
              </h6>
              <ul className="list-unstyled ps-0">
                <li className="mb-1 border-bottom">
                  <button
                    className="btn btn-toggle btn-toggle-plus"
                    data-bs-toggle="collapse"
                    data-bs-target="#culture-collapse"
                    aria-expanded="true"
                  >
                    {" "}
                    culture{" "}
                  </button>
                  <div className="collapse show" id="culture-collapse">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal small">
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 01</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 02</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 03</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 border-bottom">
                  <button
                    className="btn btn-toggle btn-toggle-plus collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#brand-collapse"
                    aria-expanded="false"
                  >
                    {" "}
                    Brand{" "}
                  </button>
                  <div className="collapse" id="brand-collapse">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal border-bottom small">
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          <span>Option 01</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          <span>Option 02</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          <span>Option 03</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 border-bottom">
                  <button
                    className="btn btn-toggle btn-toggle-plus collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#category-collapse"
                    aria-expanded="false"
                  >
                    {" "}
                    category{" "}
                  </button>
                  <div className="collapse" id="category-collapse">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal border-bottom small">
                      {allCategories.length > 0 &&
                        allCategories.map((category, index) => (
                          <li key={index}>
                            <label className="list-group-item">
                              <input
                                className="form-check-input me-1"
                                checked={checked == index ? true : false}
                                name="category-filter"
                                type="radio"
                                onChange={() => onChange(index)}
                                value={category.name}
                              />

                              <span onClick={() => getCategory(category)}>
                                {category.name}
                              </span>
                            </label>
                          </li>
                        ))}

                      {/*                       
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="category-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 02</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="category-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 03</span>
                        </label>
                      </li>
                    */}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-10">
              <div className="row mb-3 align-items-center">
                <div className="col">
                  <h6 className="text-uppercase py-3 m-0">
                    <strong>{productOfSubCategory.length}</strong>{" "}
                    <span className="text-muted-light">Products</span>
                  </h6>
                </div>
                <div className="col text-right">
                  <h6 className="text-muted-light hide-mobile m-0">
                    SORT BY:
                    <select className="select-white border-0 text-dark">
                      <option value="0">Recommended</option>
                      <option value="0">Option 02</option>
                    </select>
                  </h6>
                  <button
                    type="button"
                    className="btn-light filter-bu-mobile hide-desktop"
                    data-bs-toggle="modal"
                    data-bs-target="#filterModel"
                  >
                    <i className="bi bi-filter"></i>
                  </button>
                </div>
                <div className="col-12">
                  <div className="border-bottom border-2"></div>
                </div>
              </div>

              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4 g-4 product-grid-6">
                {productOfSubCategory.length > 0 &&
                  productOfSubCategory.map((product, index) => (
                    <div className="col">
                      <div className="card">
                        {/* {console.log("product", product)} */}
                        <img
                          src={product.image}
                          className="card-img-top"
                          alt="Product Name"
                        />
                        {}
                        <span className="wish-grid"></span>

                        <div className="card-body">
                          <p className="card-title">
                            <a href="#">{product.name}</a>
                          </p>
                        </div>

                        {product.variations.length > 1 ? (
                          <div className="card-footer">
                            <div className="row">
                              <div className="col">
                                <div className="grid-prod-price">
                                  £
                                  {product.variations[0].current_batch.product_mrp.toFixed(
                                    2
                                  )}
                                </div>
                                <div className="grid-prod-qty">
                                  /
                                  {
                                    product.variations[0].current_batch
                                      .WeightUnit
                                  }
                                </div>
                              </div>
                              <div className="col p-0 text-right">
                                {Object.entries({ product_qty: 0 }).forEach(
                                  ([key, value]) => {
                                    product.variations[0].current_batch[key] =
                                      value;
                                  }
                                )}
                                {cartItems.map((item, index) => {
                                  if (item.product_id === product.id) {
                                    Object.entries({
                                      product_qty: item.product_qty,
                                    }).forEach(([key, value]) => {
                                      product.variations[0].current_batch[key] =
                                        value;
                                    });
                                  }
                                })}
                                <AddtoCart
                                  product_qty={0}
                                  productId={product.id}
                                  product={{
                                    product_name: product.name,
                                    product_id: product.id,
                                    primary_image: product.image,
                                    product_mrp:
                                      product.variations[0].current_batch
                                        .product_mrp,
                                    // product_qty:
                                    //   product.variations[0].current_batch
                                    //     .product_qty,
                                    product_qty:
                                      product.variations[0].cart != null
                                        ? product.variations[0].cart.quantity
                                        : 5,
                                    sku_id:
                                      product.variations[0].current_batch
                                        .sku_id,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="card-footer">
                            <div className="row">
                              <div className="col">
                                <div className="grid-prod-price">
                                  £
                                  {product.variations[0].stock.seller_price.toFixed(
                                    2
                                  )}
                                </div>
                                <div className="grid-prod-qty">
                                  /
                                  {
                                    product.variations[0].current_batch
                                      .WeightUnit
                                  }
                                </div>
                              </div>
                              <div className="col p-0 text-right">
                                {Object.entries({ product_qty: 0 }).forEach(
                                  ([key, value]) => {
                                    product.variations[0].current_batch[key] =
                                      value;
                                  }
                                )}
                                {cartItems.map((item, index) => {
                                  if (item.product_id === product.id) {
                                    Object.entries({
                                      product_qty: item.product_qty,
                                    }).forEach(([key, value]) => {
                                      product.variations[0].current_batch[key] =
                                        value;
                                    });
                                  }
                                })}
                                <AddtoCart
                                  product_qty={0}
                                  quantity={
                                    product.variations[0].cart != null
                                      ? product.variations[0].cart.quantity
                                      : 0
                                  }
                                  variation_id={product.id}
                                  overide_cart=""
                                  product={{
                                    product_name: product.name,
                                    product_id: product.id,
                                    primary_image: product.image,
                                    product_mrp:
                                      product.variations[0].current_batch
                                        .product_mrp,
                                    product_qty:
                                      product.variations[0].current_batch
                                        .product_qty,
                                    sku_id:
                                      product.variations[0].current_batch
                                        .sku_id,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              {/* <div className="row mt-5">
                <div className="col">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center border-bottom border-2 border-top py-3">
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Previous">
                          <i className="bi bi-chevron-left"></i>
                        </a>{" "}
                      </li>
                      <li className="page-item">
                        <a className="page-link active" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next">
                          <i className="bi bi-chevron-right"></i>
                        </a>{" "}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Filter Modal --> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
});
export default connect(mapStateToProps)(CategoryDetails);

import React from "react";
import { Link } from "react-router-dom";

const Category = ({ allCategories }) => {
  //console.log("allCategories", allCategories);
  return (
    <section className="cuisines-list mb-4">
      <div className="container pb-5">
        <div className="row mb-4">
          <div className="col-12 page-head-with-more">
            <h2 className="heading-colored fw-bold text-uppercase m-0">
              Categories
            </h2>
          </div>
        </div>
        <div className="vertical-scroll">
          <div className="row row-cols-3 row-cols-md-6 g-4 flex-nowrap wrap-label-row">
            {allCategories.length > 0 &&
              allCategories.map((item, index) => (
                <div className="col">
                  {" "}
                  <Link to={{
                      pathname: `/category`,
                      state: {
                        index:index,
                        category: allCategories,
                        subCategory: item.sub_categories,
                        path:{category:item.name,subCategory:item.sub_categories[0].name}
                      },
                    }}>
                    <img
                      src={item.image}
                      width="100"
                      height="150"
                      alt="cuisines"
                      style={{}}
                      className="rounded-4 w-100"
                    />
                    <p className="wrap-label">{item.name}</p>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Category;

import React, { useEffect, useState } from 'react';
import { useLocation,useParams } from 'react-router-dom';
import {GetProductDetail, GetRelatedProducts} from '../../services';

import RelatedProduct from '../product/RelatedProduct';

const ProductDetails = (props) =>{

  const location = useLocation()
  const {id} = useParams(); 
  
  const [product, setProduct] = useState(location.state.product ? location.state.product : null)
  
  const [productId, setProductId] = useState(id)
  
  const [productDetails, setProductDetails] = useState([]); 
  //Brand
  const [brand, setBrand] = useState([]);
  const [brandName, setBrandName] = useState([]);
  //Variation
  const [variationsDefault, setVariationsDefault] = useState([]); 
  const [variations, setVariations] = useState([]);
  const [variationId, setVariationId] = useState([]);
  const [variationPrice, setVariationPrice] = useState([]);
  const [variationUnit, setVariationUnit] = useState([]);

  const [activeVariation, setActiveVariation] = useState('active')
  
  const [categoryId, setCategories] = useState({});  
  const [relatedProduct, setRelatedProduct] = useState([]);

  useEffect(() => {
    async function productDetail() {
      window.scrollTo(0,0)
      const res = await GetProductDetail.getProductDetail(productId);
      if (res) {
        console.log("Product details - ", res);
        setProductDetails(res.data.data.product);
        setBrand(res.data.data.product.brand);
        setBrandName(res.data.data.product.brand.name);

        setVariationsDefault(res.data.data.product.variations[0]);
        setVariations(res.data.data.product.variations);
        setVariationId(res.data.data.product.variations[0].id);
        setVariationPrice(res.data.data.product.variations[0].selling_price);
        setVariationUnit(res.data.data.product.variations[0].current_batch.WeightUnit);

        setCategories(res.data.data.product.categories[0].id);   

        await localStorage.setItem("productDetails", JSON.stringify(res.data.product));
      } else {
        console.log("error");
      }
    }

    productDetail();     
    async function relatedProducts(){      
      const res = await GetRelatedProducts.getRelatedProducts(44,categoryId,productId)  // seller_id,cat_id,product_id
      if (res) {
        console.log("Related Product - ", res);
        setRelatedProduct(res.data.data.related_products ? res.data.data.related_products :{});
      } else {
        console.log("error");
      }
    }
    relatedProducts();
    
  }, [categoryId]);

const  changeVariation = (variation)=>{
  setVariationId(variation.id)
  setVariationPrice(variation.selling_price)
  setVariationUnit(variation.current_batch.WeightUnit)
} 
    return(

        <div>
          {}
        <section className="section-breadcrumb">
  <div className="container py-3">
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb m-0">
        <li className="breadcrumb-item"><a href="#">Home</a></li>
        <li className="breadcrumb-item" aria-current="page"><a href="#">Category</a></li>
        <li className="breadcrumb-item active" aria-current="page"><a href="#">Products</a></li>
      </ol>
    </nav>
  </div>
</section>
<section className="Product-Details">
  <div className="container py-3">
    <div className="row">
      <div className="col-md-8">
           <img src={productDetails.image} className="img-fluid w-100" alt="Product Image" /> </div>
      <div className="col-md-4">
        <h6 className="text-muted m-0">{brandName}</h6>
        <h4 className="my-2 text-uppercase"><strong>{productDetails.name}</strong> <a tabindex="0" className="btn btn-info-popover" role="button" data-bs-placement="bottom" data-toggle="popover" data-bs-trigger="focus" data-bs-content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."><i className="bi bi-info"></i></a> </h4>
        <h5 className="mb-4 prod-pri">£{variationPrice}/{variationUnit}</h5>
        <div className="equal-button-3">
        {variations.length > 0 && variations.map((variation, index) => ( 
          <button type="button" onClick={()=>changeVariation(variation)} className={`btn btn-outline-warning pr-1 ${variationId==variation.id ? activeVariation : "" }`}>{variation.variation_value}</button>
        ))}          
        </div>
        <div className="my-4 border-bottom border-top py-2">
          <div className="input-group pro-amt w-50 m-auto">
            <button type="button" className="input-group-text"><i className="bi bi-dash"></i></button>
            <input type="text" className="form-control" value="1" />
            <button type="button" className="input-group-text"><i className="bi bi-plus"></i></button>
          </div>
        </div>
        <div className="">
          <button className="btn btn-dark w-100 text-uppercase" type="button">Add to basket</button>
        </div>
        <div className="my-2">
          <button className="btn btn-link w-100 fw-normal py-1" type="button"><i className="fs-5 bi-heart mr-3"></i> Add to favourites</button>
        </div>
        <div className="product-tags mt-2">
          <h6 className="text-uppercase fw-bold">Tags</h6>
          <button type="button" className="btn btn-brown mr-3 mb-2 btn-sm">Nigerian</button>
          <button type="button" className="btn btn-brown mr-3 mb-2 btn-sm">Next Tag</button>
          <button type="button" className="btn btn-brown mr-3 mb-2 btn-sm">Long Tag Example</button>
          <button type="button" className="btn btn-brown mr-3 mb-2 btn-sm">Nigerian</button>
          <button type="button" className="btn btn-brown mr-3 mb-2 btn-sm">Long Tag Example</button>
        </div>
        <div>
          <ul className="list-unstyled p-0 m-0">
            <li className="border-bottom">
              <button className="btn btn-toggle btn-toggle-plus collapsed btn-sm" data-bs-toggle="collapse" data-bs-target="#details-collapse" aria-expanded="false"> Details </button>
              <div className="collapse" id="details-collapse">
                <div className="btn-toggle-nav pb-3 list-unstyled fw-normal small"> {productDetails.short_description} </div>
              </div>
            </li>
            <li className="border-bottom">
              <button className="btn btn-toggle btn-toggle-plus collapsed btn-sm" data-bs-toggle="collapse" data-bs-target="#storage-collapse" aria-expanded="false"> Storage </button>
              <div className="collapse" id="storage-collapse">
                <div className="btn-toggle-nav pb-3 list-unstyled fw-normal small"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
              </div>
            </li>
            <li className="border-bottom">
              <button className="btn btn-toggle btn-toggle-plus collapsed btn-sm" data-bs-toggle="collapse" data-bs-target="#ingredients-collapse" aria-expanded="false"> Ingredients </button>
              <div className="collapse" id="ingredients-collapse">
                <div className="btn-toggle-nav pb-3 list-unstyled fw-normal small"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
              </div>
            </li>
            <li>
            <button className="btn btn-toggle btn-toggle-plus collapsed btn-sm" data-bs-toggle="collapse" data-bs-target="#reviews-collapse" aria-expanded="false"> reviews (1000) </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <ul className="list-unstyled ps-0">
          <li className="border-bottom">
            <div className="collapse" id="reviews-collapse">
              <div className="btn-toggle-nav list-unstyled fw-normal small">
                <div className="row pt-3">
                  <div className="col-md-4">
                    <div className="d-flex p-3"> <img src="images/user-thumb.png" alt="John Doe" width="60" height="60" className="flex-shrink-0 me-3 mt-3 rounded-circle" />
                      <div>
                        <p className="small m-0"><span className="fw-bold">User123</span> <span className="float-end">00/00/21</span></p>
                        <p className="mb-2 text-warning"><i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i></p>
                        <p className="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex p-3"> <img src="images/user-thumb.png" alt="John Doe" width="60" height="60" className="flex-shrink-0 me-3 mt-3 rounded-circle" />
                      <div>
                        <p className="small m-0"><span className="fw-bold">User123</span> <span className="float-end">00/00/21</span></p>
                        <p className="mb-2 text-warning"><i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i></p>
                        <p className="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex p-3"> <img src="images/user-thumb.png" alt="John Doe" width="60" height="60" className="flex-shrink-0 me-3 mt-3 rounded-circle" />
                      <div>
                        <p className="small m-0"><span className="fw-bold">User123</span> <span className="float-end">00/00/21</span></p>
                        <p className="mb-2 text-warning"><i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i> <i className="bi bi-star-fill"></i></p>
                        <p className="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center border-bottom border-top py-2">
                      <li className="page-item"><a className="page-link" href="#" aria-label="Previous"><i className="bi bi-chevron-left"></i></a> </li>
                      <li className="page-item"><a className="page-link active" href="#">1</a></li>
                      <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item"><a className="page-link" href="#" aria-label="Next"><i className="bi bi-chevron-right"></i></a> </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
{ Object.keys(relatedProduct).length === 0 ? "": <RelatedProduct products={relatedProduct} />}
<section className="make-a-meal">
  <div className="container py-5">
    <div className="row mb-3">
      <div className="col-12 page-head-with-more">
        <h2 className="heading-colored fw-bold text-uppercase m-0">Recently viewed</h2>
      </div>
    </div>
    <div className="row row-cols-1 row-cols-md-5 g-4 product-grid-6">
      <div className="col">
        <div className="card"> <img src="images/product01.png" className="card-img-top" alt="Product Name" />
          <div className="card-body">
            <p className="card-title"><a href="#">Product Name Two Different Lines</a></p>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col">
                <div className="grid-prod-price">£0.00</div>
                <div className="grid-prod-qty">/kg</div>
              </div>
              <div className="col p-0 text-right">
                <button className="btn btn-default btn-sm">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>      
      <div className="col">
        <div className="card"> <img src="images/product03.png" className="card-img-top" alt="Product Name" />
          <div className="card-body">
            <p className="card-title"><a href="#">Product Name Two Different Lines</a></p>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col">
                <div className="grid-prod-price">£0.00</div>
                <div className="grid-prod-qty">/kg</div>
              </div>
              <div className="col p-0">
                <div className="input-group mb-3 pro-amt">
                  <button type="button" className="input-group-text"><i className="bi bi-dash"></i></button>
                  <input type="text" className="form-control" value="1" />
                  <button type="button" className="input-group-text"><i className="bi bi-plus"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
      
    </div>
  </div>
</section>

        </div>
    )
}

export default ProductDetails;

// import { getDefaultNormalizer } from '@testing-library/dom';
import React , {useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import {UpdateProfile} from '../services';
import HomeSetting from './HomeSetting';

function MyAccount() {

    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState();
    const [country_code,setCountry_code] = useState();
    // const [password,setPassword] = useState(7897899895);
    // const [device_token,setDevice_token] = useState(7897899895);

    // useEffect(() => {
    //     async function updateProfile() {
    //     const res = await UpdateProfile.updateProfile();
    //     if (res) {
    //         console.log("updateProfile",res);
    //     } else {
    //         console.log("error");
    //     } 
    //     }
    //     updateProfile();  
        
    // }, []);

    const submitForm = async (event) =>{
        event.preventDefault();
        const res = await UpdateProfile.updateProfile(email,name,phone,country_code);
        if (res) {
            console.log("updated profile",res);
        } else {
            console.log("error");
        } 
    };


    return (
        <div>
            <div className="container py-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Settings</li>
                    </ol>
                </nav>
            </div>
            <div className="container mb-4">
                <div className="row mb-4">
                    <div className="col-12 page-head-with-more">
                        <h2 className="heading-colored fw-bold text-uppercase m-0">SETTINGS</h2>
                    </div>
                </div>
                <div className="row">
                    <HomeSetting></HomeSetting>
                    {/* <div className="col-md-4 p-0 hide-mobile">
                        <a href="/" className="side-link">My Orders</a> 
                        <a href="/" className="side-link">My addresses</a>
                        <Link to ="/my_account" className="side-link active">My account</Link> 
                        <a href="/" className="side-link">Help</a>
                        <a href="/" className="side-link">Terms and conditions</a> 
                        <a href="/" className="side-link">Privacy policy</a>
                        <Link  to="/Logout" className="side-link">Log out</Link>
                    </div> */}
                    <div className="col-md-8">
                        <form className="gray-inout px-3" onSubmit={submitForm}>
                                <h5 className="m-0 fw-bold">My Account</h5>
                                <div className="mb-3 mt-4">
                                    <label for="name" className="form-label">First name</label>
                                    <input type="text" className="form-control" placeholder="Type First Name" id="name" name="name" value={name} onChange={(e)=> setName(e.target.value)}/>
                                </div>
                                <div className="mb-3">
                                    <label for="lastname" className="form-label">Last name</label>
                                    <input type="text" className="form-control" placeholder="Type Last Name" id="lastname" />
                                </div>
                                <div className="mb-3">
                                    <label for="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" placeholder="name@emailaddress.com" id="email" name="email" value={email} onChange={(e)=> setEmail(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label for="phone" className="form-label">Phone number</label>
                                    <div className="input-group with-bg">
                                        <select className="form-select input-group-drop me-2 rounded-3" id="countrySelectDrop" onChange={(e)=> setCountry_code(e.target.value)}>
                                        <option selected>+1</option>
                                        <option value="1">+2</option>
                                        <option value="2">+3</option>
                                        <option value="3">+4</option>
                                        </select>
                                        <input type="text" className="form-control rounded-3" placeholder="Phone number" id="phone"  name="phone" value={phone} onChange={(e)=> setPhone(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label for="OldPassword" className="form-label">Old password</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="OldPassword" placeholder="********" aria-label="OldPassword" aria-describedby="basic-addon1" />
                                        <span className="input-group-text" id="basic-addon1"><i className="fs-5 bi-eye" id="toggleOldPassword"></i></span> 
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label for="NewPassword" className="form-label">New password</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="NewPassword" placeholder="********" aria-label="newpassword" aria-describedby="basic-addon1" />
                                            <span className="input-group-text" id="basic-addon1"><i className="fs-5 bi-eye" id="toggleNewPassword"></i></span> 
                                        </div>
                                    </div>
                                <div className="mb-2">
                                    <label className="form-label">Which cultural cuisines are you most interested in?</label>
                                </div>
                                <div className="row cuisines-radio">
                                <div className="col">
                                    <div className="owl-carousel owl-theme" id="carousel-example">
                                            <div className="item">
                                                <label className="select-cultural">
                                                    <input type="checkbox" value="0" />
                                                    <img src="images/product01.png" width="75" alt="cuisines" className="rounded-2 w-100" />
                                                    <p>West African</p>
                                                </label>
                                            </div>
                                            <div className="item">
                                                <label className="select-cultural">
                                                <input type="checkbox" value="0" />
                                                <img src="images/product02.png" width="75" alt="cuisines" className="rounded-2 w-100" />
                                                <p>South African</p>
                                                </label>
                                            </div>
                                            <div className="item">
                                                <label className="select-cultural">
                                                    <input type="checkbox" value="0" />
                                                    <img src="images/product03.png" width="75" alt="cuisines" className="rounded-2 w-100" /> 
                                                    <p>East African</p>
                                                </label>
                                            </div>
                                            <div className="item">
                                                <label className="select-cultural">
                                                    <input type="checkbox" value="0" />
                                                    <img src="images/product04.png" width="75" alt="cuisines" className="rounded-2 w-100" />
                                                    <p>Jamaican</p>
                                                </label>
                                            </div>
                                            <div className="item">
                                                <label className="select-cultural">
                                                    <input type="checkbox" value="0" />
                                                    <img src="images/product05.png" width="75" alt="cuisines" className="rounded-2 w-100"/>
                                                    <p>West African</p>
                                                </label>
                                            </div>
                                            <div className="item">
                                                <label className="select-cultural">
                                                    <input type="checkbox" value="0" />
                                                    <img src="images/product06.png" width="75" alt="cuisines" className="rounded-2 w-100" />
                                                    <p>South African</p>
                                                </label>
                                            </div>
                                            <div className="item">
                                                <label className="select-cultural">
                                                    <input type="checkbox" value="0" />
                                                    <img src="images/product01.png" width="75" alt="cuisines" className="rounded-2 w-100" />
                                                    <p>East African</p>
                                                </label>
                                            </div>
                                            <div className="item">
                                                <label className="select-cultural">
                                                    <input type="checkbox" value="0" />
                                                    <img src="images/product02.png" width="75" alt="cuisines" className="rounded-2 w-100" />
                                                    <p>Jamaican</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>       
                            <div className="my-3">
                                <button type="submit" className="btn btn-dark btn-lg mb-3 w-100 text-uppercase">Save</button>
                            </div>
                        </form>
                     </div>
                </div>
            </div>
        </div>
    )

}

export default MyAccount;

import React from "react";
import { Link } from "react-router-dom";
const EmptyBasket = (props) => {
  return (
    <div>
      <section className="section-breadcrumb">
        <div className="container py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={"/"}>Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Link to={"/basket"}>Basket</Link>
              </li>

              {/* <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Basket
              </li> */}

            </ol>
          </nav>
        </div>
      </section>
      <section className="wishlist-Products">
        <div className="container pb-5">
          <div className="row mb-4">
            <div className="col-12 page-head-with-more">
              <h2 className="heading-colored fw-bold text-uppercase m-0">
                Basket
              </h2>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-md-5 text-center m-auto">
              <h3 className="fw-bold m-0 fs-1">Your basket is empty</h3>
              <p className="text-muted my-4 fs-21">
                Items will appear here after
                <br />
                you have added them
              </p>
              <button
                className="btn btn-default text-uppercase w-100 mt-3 mb-5"
                type="button"
              >
                <Link to="/" style={{ color: "#fff" }}>
                  SHOP NOW
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmptyBasket;

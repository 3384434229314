const API_URL =
  document.domain === "localhost"
    // ? "http://3.15.136.179/oja/public/api/v1"
    ?"http://18.222.180.36/oja/public/api/v1"
    : "http://18.222.180.36/oja/public/api/v1"; //"https://my-oja.com/oja/public/api/v1";

const Apis = {
  //Nitesh @ Apis
  //Authentication api /popularProducts
  // @Nitesh
  // GetAllCategoryList: `${API_URL}/api/category/cn/list?slug=`,
  // GetAllHomePageData : `${API_URL}/home/user/home/v1?partnerId=${partnerId}`,
  GetPopularProduct: `${API_URL}/popularProducts`,
  GetCulturesList: `${API_URL}/getCulturesList`,
  GetCategoryList: `${API_URL}/getSellerStoreDetail/`,
  
  GetProductById: `${API_URL}/productDetails`,
  // GetCategoryList: `${API_URL}/getCategoryList`,
  CheckEmail:`${API_URL}/checkEmail`,
  Login:`${API_URL}/login`,
  Register:`${API_URL}/register`,
  UpdateProfile: `${API_URL}/updateProfile`,
  GetMyAddressList: `${API_URL}/getMyAddressList`,
  GetMyOrder: `${API_URL}/myOrders`,


  GetProductsOfSubCategory: `${API_URL}/productsOfSubCategory`,

  GetProductDetail: `${API_URL}/productDetails/`,
  GetListOfAllThings: `${API_URL}/getListOfAllThings`,
  GetRelatedProducts: `${API_URL}/getRelatedProducts`,
  CheckDeliveryPostcode:`${API_URL}/checkDeliveryPostcode`,
  AddOrUpdateCart: `${API_URL}/addOrUpdateCart`,
  GetMyCartData:`${API_URL}/myCart`,

  RemoveFromMyCart: `${API_URL}/removeFromMyCart`,
};
export { API_URL, Apis };

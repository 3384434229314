import React from "react";

const MyOrder = (props) =>{
    return(
        <div>
            <div className="container py-3">
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="#">Home</a></li>
      <li className="breadcrumb-item active" aria-current="page">Settings</li>
    </ol>
  </nav>
</div>
<div className="container mb-4">
  <div className="row mb-4">
    <div className="col-12 page-head-with-more">
      <h2 className="heading-colored fw-bold text-uppercase m-0">SETTINGS</h2>
    </div>
  </div>
  <div className="row">
    <div className="col-md-4 hide-mobile"><a href="My-Orders.html" className="side-link active">My Orders</a> <a href="My-Addresses.html" className="side-link">My addresses</a> <a href="My-Account.html" className="side-link">My account</a> <a href="Help.html" className="side-link">Help</a> <a href="Terms-and-conditions.html" className="side-link">Terms and conditions</a> <a href="Privacy-policy.html" className="side-link">Privacy policy</a> <a href="Login.html" className="side-link">Log out</a></div>
    <div className="col-md-8">
      <div className="accordion accordion-plus accordionMyOrders" id="accordionMyOrders">
        <div className="accordion-item border-0">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">In progress</button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionMyOrders">
            <div className="accordion-body p-0">
              <div className="accordion accordionOrdersDetails" id="accordionOrdersDetails">
                <div className="accordion-item border-0">
                  <div className="row accordion-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOneOne" aria-expanded="true" aria-controls="collapseOneOne">
                    <div className="col">Order # 00000</div>
                    <div className="col">Stephenson Close,<br />
                      Pink Road, SW9 7UT</div>
                    <div className="col text-warning">Fri, 10 Sept 2021</div>
                  </div>
                  <div id="collapseOneOne" className="accordion-collapse collapse" aria-labelledby="collapseOneOne" data-bs-parent="#accordionOrdersDetails">
                    <div className="accordion-body px-0">
                      <div className="product-ordered">
                        <div className="row">
                          <div className="col-md-4"> </div>
                          <div className="col-md-8">
                            <div className="d-flex"> <img src="images/product02.png" width="44" className="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8">
                                    <div className="">Product Name Over<br/>
                                      Two Lines</div>
                                    <div className="text-BlueGray pt-1"><span className="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div className="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-separator"></div>
                            <div className="d-flex"> <img src="images/product03.png" width="44" className="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8">
                                    <div className="">Product Name Over<br />
                                      Two Lines</div>
                                    <div className="text-BlueGray pt-1"><span className="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div className="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-separator"></div>
                            <div className="d-flex">
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8 fs-6">Total (2 items)</div>
                                  <div className="col-4 text-right fs-6"> £10.00 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <div className="row accordion-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwoTwo" aria-expanded="true" aria-controls="collapseTwoTwo">
                    <div className="col">Order # 00000</div>
                    <div className="col">Stephenson Close,<br />
                      Pink Road, SW9 7UT</div>
                    <div className="col text-warning">Fri, 10 Sept 2021</div>
                  </div>
                  <div id="collapseTwoTwo" className="accordion-collapse collapse" aria-labelledby="headingTwoTwo" data-bs-parent="#accordionOrdersDetails">
                    <div className="accordion-body px-0">
                      <div className="product-ordered">
                        <div className="row">
                          <div className="col-md-4"> </div>
                          <div className="col-md-8">
                            <div className="d-flex"> <img src="images/product02.png" width="44" className="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8">
                                    <div className="">Product Name Over<br />
                                      Two Lines</div>
                                    <div className="text-BlueGray pt-1"><span className="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div className="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-separator"></div>
                            <div className="d-flex"> <img src="images/product03.png" width="44" className="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8">
                                    <div className="">Product Name Over<br />
                                      Two Lines</div>
                                    <div className="text-BlueGray pt-1"><span className="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div className="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-separator"></div>
                            <div className="d-flex">
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8 fs-6">Total (2 items)</div>
                                  <div className="col-4 text-right fs-6"> £10.00 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item border-0">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Previous orders</button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionMyOrders">
            <div className="accordion-body p-0">
              <div className="accordion accordionOrdersDetails" id="accordionOrdersDetails2">
                <div className="accordion-item border-0">
                  <div className="row accordion-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThreeThree" aria-expanded="true" aria-controls="collapseThreeThree">
                    <div className="col">Order # 00000</div>
                    <div className="col">Stephenson Close,<br />
                      Pink Road, SW9 7UT</div>
                    <div className="col text-warning">Fri, 10 Sept 2021</div>
                  </div>
                  <div id="collapseThreeThree" className="accordion-collapse collapse" aria-labelledby="collapseThreeThree" data-bs-parent="#accordionOrdersDetails2">
                    <div className="accordion-body px-0">
                      <div className="product-ordered">
                        <div className="row">
                          <div className="col-md-4"> </div>
                          <div className="col-md-8">
                            <div className="d-flex"> <img src="images/product02.png" width="44" className="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8">
                                    <div className="">Product Name Over<br />
                                      Two Lines</div>
                                    <div className="text-BlueGray pt-1"><span className="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div className="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-separator"></div>
                            <div className="d-flex"> 
                            <img src="images/product03.png" width="44" className="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8">
                                    <div className="">Product Name Over<br />
                                      Two Lines</div>
                                    <div className="text-BlueGray pt-1"><span className="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div className="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-separator"></div>
                            <div className="d-flex">
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8 fs-6">Total (2 items)</div>
                                  <div className="col-4 text-right fs-6"> £10.00 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <div className="row accordion-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFourFour" aria-expanded="true" aria-controls="collapseFourFour">
                    <div className="col">Order # 00000</div>
                    <div className="col">Stephenson Close,<br />
                      Pink Road, SW9 7UT</div>
                    <div className="col text-warning">Fri, 10 Sept 2021</div>
                  </div>
                  <div id="collapseFourFour" className="accordion-collapse collapse" aria-labelledby="headingFourFour" data-bs-parent="#accordionOrdersDetails2">
                    <div className="accordion-body px-0">
                      <div className="product-ordered">
                        <div className="row">
                          <div className="col-md-4"> </div>
                          <div className="col-md-8">
                            <div className="d-flex"> 
                            <img src="images/product02.png" width="44" className="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8">
                                    <div className="">Product Name Over<br />
                                      Two Lines</div>
                                    <div className="text-BlueGray pt-1"><span className="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div className="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-separator"></div>
                            <div className="d-flex">
                                 <img src="images/product03.png" width="44" className="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8">
                                    <div className="">Product Name Over<br />
                                      Two Lines</div>
                                    <div className="text-BlueGray pt-1"><span className="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div className="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-separator"></div>
                            <div className="d-flex">
                              <div className="w-100">
                                <div className="row">
                                  <div className="col-8 fs-6">Total (2 items)</div>
                                  <div className="col-4 text-right fs-6"> £10.00 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
    )
}

export default MyOrder;
import { ADD_TO_CART, REMOVE_FROM_CART, INCREASE_QUANTITY, DECREASE_QUANTITY, CART_COUNT } from "./types";

export const addToCart = (product,count,product_id) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  let alreadyExists = false;
  cartItems.forEach((x) => {
    if (x.product_id === product_id) {
      alreadyExists = true;
    }
  });
  if (!alreadyExists) {
    cartItems.push({...product,product_qty:count+1});
  }
  dispatch({
    type: ADD_TO_CART,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

////////////////////////////////////////////

export const productCount = (cart_count) => (dispatch, getState) => {
  // const cartItems = getState().cart.cartItems.slice()
  console.log("cart_count", cart_count)
  dispatch({
    type: CART_COUNT,
    payload: { cart_count }
  });
  localStorage.setItem("cart_count", cart_count);
}



/////////////////////////////////////////////////////

export const incrementToCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice()
  const selectProduct = cartItems.find(item => (item.product_id === product.product_id))

  let index = cartItems.indexOf(selectProduct);
  let value = cartItems[index];
  value.product_qty = value.product_qty + 1;
  value.total = value.product_qty * value.product_mrp;
  dispatch({
    type: INCREASE_QUANTITY,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
}

/////////////////////////////////////////////////////////////
export const removeFromCart = (product) => (dispatch, getState) => {

  console.log("removeFromCart",product);

  // const cartItems = getState().cart.cartItems;

  const cartItems = getState().cart.cartItems.slice()
  .filter((x) => x.product_id !== product.product_id );
  console.log("cartItems ---- ", cartItems)
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
  dispatch({ type: REMOVE_FROM_CART, payload: { cartItems } });

  // window.location.reload(true)
};

export const decreaseToCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  const selectProduct = cartItems.find(item => item.product_id === product.product_id)
  const index = cartItems.indexOf(selectProduct)
  const value = cartItems[index]

  if (value.product_qty > 0) {
    value.product_qty = value.product_qty - 1;
  value.total = value.product_qty * value.product_mrp;
  }
  localStorage.setItem("cartItems", JSON.stringify(cartItems));

  dispatch({ type: DECREASE_QUANTITY, payload: { cartItems } });
}

///////////////////////////////////////////////////////////////////////////////////////









import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Header from "./components/comman/Header";
import ProductDetails from "./components/product/ProductDetails";
import Footer from "./components/comman/Footer";
import Culture from "./components/product/Culture";
import ProtectedRoute from "./components/ProtectedRoute";
import CategoryDetails from "./components/category/CategoryDetails";
import Registration from "./components/Registration";
import Login from "./components/Login";
import Logout from "./components/Logout";
import LoginWithPassword from "./components/LoginWithPassword";

import MyAccount from "./components/MyAccount";
import MyAddress from "./components/MyAddress";
import Help from "./components/Help";
import HomeSetting from "./components/HomeSetting";
import AddAddress from "./components/AddAddress";
import MyOrder from "./components/MyOrder";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Basket from "./components/cart/Basket";



import { Provider } from "react-redux";
import store from "./store";
import Account from "./components/auth/Account";
import OrderConfirm from "./components/checkout/OrderConfirm";
import MyOrders from "./components/checkout/MyOrders";
import Checkout from "./components/checkout/Checkout";


function App() {

  return (
    <Provider store={store}>
      <div>
        <Router>
          <Header/>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login}/>
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/basket" component={Basket} />

            <Route
              exact
              path="/login-with-password"
              component={LoginWithPassword}
            />
            <Route exact path="/registration" component={Registration} />
            <Route exact path="/product/:id" component={ProductDetails} />
            <Route exact path="/category" component={CategoryDetails} />
            <Route exact path="/culture" component={Culture} />
            <Route exact path="/account" component={Account} />
            {/* <Route exact path="/account" component={Account} /> */}
            <ProtectedRoute exact path="/checkout" component={Checkout} />
            <Route exact path="/orderConfirm" component={OrderConfirm} />
            <Route exact path="/myorders" component={MyOrders} />



            <Route exact path="/my_address" component={MyAddress} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/termsandconditions" component={TermsAndConditions} />
            <Route exact path="/homeSetting" component={HomeSetting} />
            <Route exact path="/privacy_policy" component={PrivacyPolicy} />
            <Route exact path="/add_address" component={AddAddress} />
            <Route exact path="/my_order" component={MyOrder} />
            <Route exact path="/my_account" component={MyAccount} />
            {/* <ProtectedRoute exact path="/my_account" component={MyAccount} /> */}
          </Switch>
          <Footer/>
        </Router>
      </div>
    </Provider>
  );
}

export default App;

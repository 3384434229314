import React , {useEffect} from 'react';
// import {Link} from 'react-router-dom';
//import { useHistory } from 'react-router-dom';



function Logout() {

    // const History = useHistory();

    useEffect(() => {
       localStorage.clear();
       setInterval(() => {
           window.location = "/login"
       }, 100);
    //    History.push('/login');
    });
    
    return (
        <div className="text-center">
            <h3>You have been logout successfully</h3>
            {/* <Link to="/login">move to login</Link> */}
        </div>
    )
}

export default Logout

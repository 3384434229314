import React from 'react'
import HomeSetting from './HomeSetting'

function AddAddress() {
    return (
        <div>
            <div className="container py-3">
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="#">Home</a></li>
      <li className="breadcrumb-item active" aria-current="page">Settings</li>
    </ol>
  </nav>
</div>
<div className="container mb-4">
  <div className="row mb-4">
    <div className="col-12 page-head-with-more">
      <h2 className="heading-colored fw-bold text-uppercase m-0">SETTINGS</h2>
    </div>
  </div>
  <div className="row">
    <HomeSetting></HomeSetting>
    {/* <div className="col-md-4 hide-mobile">
        <a href="My-Orders.html" className="side-link">My Orders</a> 
        <a href="My-Addresses.html" className="side-link active">My addresses</a> 
        <a href="My-Account.html" className="side-link">My account</a> 
        <a href="Help.html" className="side-link">Help</a> 
        <a href="Terms-and-conditions.html" className="side-link">Terms and conditions</a>
         <a href="Privacy-policy.html" className="side-link">Privacy policy</a>
          <a href="Login.html" className="side-link">Log out</a>
          </div> */}
    <div className="col-md-8 gray-inout">
      <div className="mb-3 mt-3">
        <label for="enter-postcode" className="form-label mb-3">Refine drop-off location</label>
        <img src="images/locate-address.svg" className="img-fluid w-100" title="" /> </div>
      <p className="m-0 fw-bold text-uppercase my-4 py-2">TAGS</p>
      <div className="row text-center">
        <div className="col">
          <label className="select-address-type">
          <input type="radio" value="0" name="select-address-type-radio" />
          <div className="icon-hom-add2"><img src="images/icon-home.svg" width="31" title="Address Home" /></div>
          <p>Home</p>
          </label>
        </div>
        <div className="col">
          <label className="select-address-type">
          <input type="radio" value="0" name="select-address-type-radio" />
          <div className="icon-fam-add2"><img src="images/icon-family.svg" width="31" title="Address Home" /></div>
          <p>Falily</p>
          </label>
        </div>
        <div className="col">
          <label className="select-address-type">
          <input type="radio" value="0" name="select-address-type-radio" />
          <div className="icon-off-add2"><img src="images/icon-office.svg" width="31" title="Address Home" /></div>
          <p>Office</p>
          </label>
        </div>
        <div className="col">
          <label className="select-address-type">
          <input type="radio" value="0" name="select-address-type-radio" />
          <div className="icon-cus-add2"><img src="images/icon-custom.svg" width="31" title="Address Home" /></div>
          <p>Custom</p>
          </label>
        </div>
        <div className="col">
          <label className="select-address-type">
          <input type="radio" value="0" name="select-address-type-radio" />
          <div className="icon-cus-add2"><img src="images/icon-custom2.svg" width="31" title="Address Home" /></div>
          <p>Custom</p>
          </label>
        </div>
      </div>
      <p className="m-0 fw-bold text-uppercase my-4 py-2">DELIVERY ADDRESS</p>
      <div className="row">
        <div className="col-md-12 mb-2">
          <label for="enter-postcode" className="form-label">Post code</label>
          <div className="input-group">
            <input type="text" className="form-control border-0" placeholder="Enter postcode" aria-label="enter-postcode" aria-describedby="enter-postcode" />
            <button className="btn btn-light" type="button" id="enter-postcode"><i className="bi bi-search"></i></button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-2">
          <label for="flat-number" className="form-label">Flat number</label>
          <input type="text" className="form-control" placeholder="Enter flat number" aria-label="flat-number" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-2">
          <label for="street-number" className="form-label">Street</label>
          <input type="text" className="form-control" placeholder="Enter street number" aria-label="street-number" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-4">
          <label for="selectCity" className="form-label">City</label>
          <select className="form-select" id="">
            <option selected="">Option 01</option>
            <option value="1">Option 02</option>
          </select>
        </div>
        <div className="col-md-6 mb-2">
          <label for="selectCountry" className="form-label">Country</label>
          <select className="form-select" id="">
            <option selected="">Option 01</option>
            <option value="1">Option 02</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-2">
          <button type="submit" className="btn btn-dark btn-lg w-100 text-uppercase">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
    )
}

export default AddAddress

import React from "react";
import { Link } from "react-router-dom";

const OrderConfirm = (props) => {
  return (
    <div>
      <section className="Order-confirmed">
        <div className="container py-4">
          <div className="row py-3">
            <div className="col-md-5 text-center m-auto">
              <i className="bi bi-check-circle-fill order-success-icon"></i>
              <h3 className="fw-bold m-0 fs-1">Thank you for your order!</h3>
              <p className="my-3 fs-24 text-borwn">Order #00000</p>
              <p className="my-3 fs-18 text-gray">
                Now its time to put your feet up and let
                <br />
                us do the rest of the work
              </p>
              <Link
                className="btn btn-outline-black text-uppercase w-100 mt-3"
                to="/myorders"
              >
                track your order
              </Link>
              <a
                className="btn btn-default text-uppercase w-100 mt-3"
                href="index.html"
              >
                continue shopping
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default OrderConfirm;

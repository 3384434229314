import api from '../../config/ApiConfig';
import { Apis } from '../../config';
import axios from 'axios';
// import { NotificationManager } from 'react-notifications';

const updateProfile = async (email,name,phone,country_code) => {

    let data = { email: `${email}`, name : `${name}`,phone : `${phone}`, country_code : `${country_code}`};
    //let data = {email:"abc@g.com",name:"ni",phone:"98352252",country_code:"91"}
    try {
        let result = await api.post(Apis.UpdateProfile,data);
        if (result) {
            //NotificationManager.error(result.data.error);
            return result;
        }
        // return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default {
    updateProfile,
};
// import api from "../../config/ApiConfig";
import { Apis } from "../../config";
import axios from "axios";
// import { NotificationManager } from 'react-notifications';

const checkEmail = async (email) => {
  let data = { email: `${email}` };
  try {
    let result = await axios.post(Apis.CheckEmail, data);
    if (result) {
      // NotificationManager.error(result.data.error);
      return result;
    }
    // return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default {
  checkEmail,
};

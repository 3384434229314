import React from 'react'
import HomeSetting from './HomeSetting';

function Help() {

    return (
        <div>
            <div className="container py-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Settings</li>
                    </ol>
                </nav>
            </div>
            <div className="container mb-4">
                <div className="row mb-4">
                    <div className="col-12 page-head-with-more">
                    <h2 className="heading-colored fw-bold text-uppercase m-0">SETTINGS</h2>
                    </div>
                </div>
                <div className="row">
                    <HomeSetting></HomeSetting>
                    {/* <div className="col-md-4 hide-mobile">
                        <a href="My-Orders.html" className="side-link">My Orders</a> 
                        <a href="My-Addresses.html" className="side-link">My addresses</a> 
                        <a href="My-Account.html" className="side-link">My account</a>
                        <a href="Help.html" className="side-link active">Help</a>
                        <a href="Terms-and-conditions.html" className="side-link">Terms and conditions</a> 
                        <a href="Privacy-policy.html" className="side-link">Privacy policy</a>
                        <a href="Login.html" className="side-link">Log out</a>
                    </div> */}
                    <div className="col-md-8 gray-inout">
                        <p className="m-0 fw-bold text-uppercase mb-3">help center</p>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                            <label for="enter-postcode" className="form-label text-dark mb-2">Have any question? Ask here</label>
                            <textarea rows="6" className="form-control" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem et tortor consequat id porta. Erat nam at lectus urna duis convallis convallis tellus. Mauris a diam maecenas sed enim ut sem viverra. Nisi lacus sed viverra tellus in." aria-label="flat-number"></textarea>
                            </div>
                        </div>
                        {/* style="min-height:165px" */}
                        {/* style="width:288px" */}
                        <div className="row">
                            <div className="col-md-12 mb-2 text-right">
                            <button type="submit" className="btn btn-dark btn-lg text-uppercase" >Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );      
}

export default Help

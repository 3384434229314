import React, { useState } from "react";

const AddtoCart = (props) => {
  let { product_qty } = props;
  console.log("props", props)
  const [count, setCount] = useState(product_qty);
  return (
    <>
      {count === 0 ? (
        <div className="col p-0 text-right">
          <button
            className="btn btn-default btn-sm"
            onClick={() => setCount(count + 1)}
          >
            Add
          </button>
        </div>
      ) : (
        <div className="col p-0">
          <div className="input-group mb-3 pro-amt">
            <button
              type="button"
              className="input-group-text"
              onClick={() => setCount(count - 1)}
            >
              <i className="bi bi-dash"></i>
            </button>
            <input type="text" className="form-control" value={count} />
            <button
              type="button"
              className="input-group-text"
              onClick={() => setCount(count + 1)}
            >
              <i className="bi bi-plus"></i>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddtoCart;





import React,{useState,useEffect}from 'react';
import validator from 'validator'
import {CheckEmail} from '../services';
import facebook from '../images/facebook.png';
import google from '../images/google.png';
import apple from '../images/apple.png';
import { useHistory } from 'react-router-dom';


function Login() {
        
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    // const [msg, setmsg] = useState();
    const [email,setEmail] = useState();
    const [emailError, setEmailError] = useState('');
    const [emailErr, setEmailErr] = useState('');
    


    const History = useHistory();

    const submitForm = async (event) =>{
        event.preventDefault();
        
        if (validator.isEmail(emailErr)) {
            const res = await CheckEmail.checkEmail(email);
            if (res) {
                // setmsg(res.data.message);
                console.log("checkEmail",res)
                if(res.data.status === 0){
                    localStorage.setItem("loginEmail", email);
                    History.push("/login-with-password");
                }else if(res.data.status === 1){
                    History.push("/registration");
                }
            } else {
                console.log("error");
            } 
        } else {
            setEmailError('enter valid email !')
        }
    };

    const inputEvent = (event)=>{
        setEmailErr(event.target.value); 
        setEmail(event.target.value);
    };

    return (
        <div>
            <div className="container py-5">
                <div className="row mb-3">
                    <div className="col-md-4 m-auto form-grey">
                        <h1 className="mb-4 text-center migra-font text-dark">Sign in</h1>
                        <div className="d-grid gap-2">
                            <button type="button" className="btn btn-dark btn btn-secondary"><img src={apple} className="mr-3" height="20" alt="" />Sign in with Apple</button>
                            <button type="button" className="btn btn-outline-blue  btn btn-light"><img src={facebook} className="mr-3" height="20" alt="" />Continue with Facebook</button>
                            <button type="button" className="btn btn-outline-black  btn btn-light"><img src={google} className="mr-3" height="20" alt="" />Continue with Google</button>
                            <p className="text-muted my-3 text-center">or continue with</p>
                            <form onSubmit={submitForm}>
                                <div className="mb-3">
                                    <input type="email" className="form-control" placeholder="name@emailaddress.com" id="email" name="email" onChange={inputEvent} value={email} />
                                    {/* <p style={{color:'red'}} className="m-0">{msg}</p> */}
                                    <p style={{color:'red'}}>{emailError}</p>
                                </div>              
                                <div className="mt-4">
                                    <button type="submit" className="btn btn-default text-uppercase w-100">sign in</button>
                                    {/* <a href="login-with-password.html" className="btn btn-default text-uppercase w-100">sign in</a> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login

import React,{useEffect}from 'react'
import HomeSetting from './HomeSetting'
import { GetMyOrder } from '../services';

function MyOrder() {

  useEffect(() => {
    async function orderDetails() {
      const res = await GetMyOrder.getMyOrder();
      if (res) {
        console.log("getMyOrder - ", res);
      } else {
        console.log("error");
      }
    }
    orderDetails();  
  });


    return (
        <div>
            <div class="container py-3">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Settings</li>
    </ol>
  </nav>
</div>
<div class="container mb-4">
  <div class="row mb-4">
    <div class="col-12 page-head-with-more">
      <h2 class="heading-colored fw-bold text-uppercase m-0">SETTINGS</h2>
    </div>
  </div>
  <div class="row">
      <HomeSetting></HomeSetting>
    {/* <div class="col-md-4 hide-mobile">
        <a href="My-Orders.html" class="side-link active">My Orders</a> 
        <a href="My-Addresses.html" class="side-link">My addresses</a>
         <a href="My-Account.html" class="side-link">My account</a> 
         <a href="Help.html" class="side-link">Help</a>
          <a href="Terms-and-conditions.html" class="side-link">Terms and conditions</a> 
          <a href="Privacy-policy.html" class="side-link">Privacy policy</a> 
          <a href="Login.html" class="side-link">Log out</a>
          </div> */}
    <div class="col-md-8">
      <div class="accordion accordion-plus accordionMyOrders" id="accordionMyOrders">
        <div class="accordion-item border-0">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">In progress</button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionMyOrders">
            <div class="accordion-body p-0">
              <div class="accordion accordionOrdersDetails" id="accordionOrdersDetails">
                <div class="accordion-item border-0">
                  <div class="row accordion-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOneOne" aria-expanded="true" aria-controls="collapseOneOne">
                    <div class="col">Order # 00000</div>
                    <div class="col">Stephenson Close,<br/>
                      Pink Road, SW9 7UT</div>
                    <div class="col text-warning">Fri, 10 Sept 2021</div>
                  </div>
                  <div id="collapseOneOne" class="accordion-collapse collapse" aria-labelledby="collapseOneOne" data-bs-parent="#accordionOrdersDetails">
                    <div class="accordion-body px-0">
                      <div class="product-ordered">
                        <div class="row">
                          <div class="col-md-4"> </div>
                          <div class="col-md-8">
                            <div class="d-flex"> <img src="images/product02.png" width="44" class="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8">
                                    <div class="">Product Name Over<br/>
                                      Two Lines</div>
                                    <div class="text-BlueGray pt-1"><span class="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div class="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div class="border-bottom-separator"></div>
                            <div class="d-flex"> <img src="images/product03.png" width="44" class="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8">
                                    <div class="">Product Name Over<br />
                                      Two Lines</div>
                                    <div class="text-BlueGray pt-1"><span class="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div class="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div class="border-bottom-separator"></div>
                            <div class="d-flex">
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8 fs-6">Total (2 items)</div>
                                  <div class="col-4 text-right fs-6"> £10.00 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item border-0">
                  <div class="row accordion-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwoTwo" aria-expanded="true" aria-controls="collapseTwoTwo">
                    <div class="col">Order # 00000</div>
                    <div class="col">Stephenson Close,<br />
                      Pink Road, SW9 7UT</div>
                    <div class="col text-warning">Fri, 10 Sept 2021</div>
                  </div>
                  <div id="collapseTwoTwo" class="accordion-collapse collapse" aria-labelledby="headingTwoTwo" data-bs-parent="#accordionOrdersDetails">
                    <div class="accordion-body px-0">
                      <div class="product-ordered">
                        <div class="row">
                          <div class="col-md-4"> </div>
                          <div class="col-md-8">
                            <div class="d-flex"> <img src="images/product02.png" width="44" class="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8">
                                    <div class="">Product Name Over<br />
                                      Two Lines</div>
                                    <div class="text-BlueGray pt-1"><span class="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div class="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div class="border-bottom-separator"></div>
                            <div class="d-flex"> <img src="images/product03.png" width="44" class="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8">
                                    <div class="">Product Name Over<br />
                                      Two Lines</div>
                                    <div class="text-BlueGray pt-1"><span class="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div class="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div class="border-bottom-separator"></div>
                            <div class="d-flex">
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8 fs-6">Total (2 items)</div>
                                  <div class="col-4 text-right fs-6"> £10.00 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item border-0">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Previous orders</button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionMyOrders">
            <div class="accordion-body p-0">
              <div class="accordion accordionOrdersDetails" id="accordionOrdersDetails2">
                <div class="accordion-item border-0">
                  <div class="row accordion-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThreeThree" aria-expanded="true" aria-controls="collapseThreeThree">
                    <div class="col">Order # 00000</div>
                    <div class="col">Stephenson Close,<br />
                      Pink Road, SW9 7UT</div>
                    <div class="col text-warning">Fri, 10 Sept 2021</div>
                  </div>
                  <div id="collapseThreeThree" class="accordion-collapse collapse" aria-labelledby="collapseThreeThree" data-bs-parent="#accordionOrdersDetails2">
                    <div class="accordion-body px-0">
                      <div class="product-ordered">
                        <div class="row">
                          <div class="col-md-4"> </div>
                          <div class="col-md-8">
                            <div class="d-flex"> <img src="images/product02.png" width="44" class="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8">
                                    <div class="">Product Name Over<br />
                                      Two Lines</div>
                                    <div class="text-BlueGray pt-1"><span class="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div class="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div class="border-bottom-separator"></div>
                            <div class="d-flex"> <img src="images/product03.png" width="44" class="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8">
                                    <div class="">Product Name Over<br />
                                      Two Lines</div>
                                    <div class="text-BlueGray pt-1"><span class="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div class="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div class="border-bottom-separator"></div>
                            <div class="d-flex">
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8 fs-6">Total (2 items)</div>
                                  <div class="col-4 text-right fs-6"> £10.00 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item border-0">
                  <div class="row accordion-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFourFour" aria-expanded="true" aria-controls="collapseFourFour">
                    <div class="col">Order # 00000</div>
                    <div class="col">Stephenson Close,<br />
                      Pink Road, SW9 7UT</div>
                    <div class="col text-warning">Fri, 10 Sept 2021</div>
                  </div>
                  <div id="collapseFourFour" class="accordion-collapse collapse" aria-labelledby="headingFourFour" data-bs-parent="#accordionOrdersDetails2">
                    <div class="accordion-body px-0">
                      <div class="product-ordered">
                        <div class="row">
                          <div class="col-md-4"> </div>
                          <div class="col-md-8">
                            <div class="d-flex"> <img src="images/product02.png" width="44" class="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8">
                                    <div class="">Product Name Over<br />
                                      Two Lines</div>
                                    <div class="text-BlueGray pt-1"><span class="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div class="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div class="border-bottom-separator"></div>
                            <div class="d-flex"> <img src="images/product03.png" width="44" class="img-fluid me-3 rounded-3 align-self-start" alt="" />
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8">
                                    <div class="">Product Name Over<br />
                                      Two Lines</div>
                                    <div class="text-BlueGray pt-1"><span class="qty">Quantity 0.0 kg</span></div>
                                  </div>
                                  <div class="col-4 text-right"> £5.00 </div>
                                </div>
                              </div>
                            </div>
                            <div class="border-bottom-separator"></div>
                            <div class="d-flex">
                              <div class="w-100">
                                <div class="row">
                                  <div class="col-8 fs-6">Total (2 items)</div>
                                  <div class="col-4 text-right fs-6"> £10.00 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
    )
}

export default MyOrder

import api from '../../config/ApiConfig';
import { Apis } from '../../config';
import axios from 'axios'
// import { NotificationManager } from 'react-notifications';

const getMyCartData = async () => {
    try {
        
        let result = await api.get(Apis.GetMyCartData);
        // let result = await axios.get(Apis.GetCategoryList+sellerId);
        if (result) {
            // NotificationManager.error(result.data.error);
            return result;
        }
        // return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default {
    getMyCartData,
};
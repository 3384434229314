import api from '../../config/ApiConfig';
import { Apis } from '../../config';
import axios from 'axios'

const login = async (email,password,device_token,is_social) => {
    let data = {"email": `${email}`,"password": `${password}`,"device_token": `${device_token}`,"is_social": `${is_social}`};
    try {
        let result = await axios.post(Apis.Login, data);
        if (result) {
            return result;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default {
    login,
    
};
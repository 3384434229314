import React from "react";
import { Link } from "react-router-dom";
import AddtoCart from "../comman/AddToCart";
import AddtoCart1 from "../comman/AddToCart1";

const Product = (props) => {
  let { products } = props;
  console.log(products);
  return (
    <section className="make-a-meal">
        <div className="container py-5">
            <div className="row mb-3">
                <div className="col-12 page-head-with-more">
                <h2 className="heading-colored fw-bold text-uppercase m-0">
                    Make a Meal
                    <span className="text-sub-head">Try one of our meal kits</span>
                </h2>
                <button type="button" className="btn btn-link view-more-but">
                    More <i className="bi bi-chevron-right"></i>
                </button>
                </div>
            </div>
          <div className="row row-cols-2 row-cols-md-5 g-4 product-grid-6">
          {products.length > 0 &&
            products.map((item, index) => (  
            <div className="col" key={index}>
                <div className="card">
                    <Link
                        to={{
                            pathname: `/product`,
                            state: {
                              product: item,
                              productId: item.id,
                            },
                        }}
                    >
                    <img
                    src={item.image}
                    className="card-img-top"
                    alt="Product Name"
                    />
                    </Link>
                    <div className="card-body">
                    <p className="card-title">
                    <Link
                        to={{
                            pathname: `/product`,
                            state: {
                              product: item,
                              productId: item.id,
                            },
                        }}

                    >
                        {item.name}
                    </Link> 
                    </p>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                        <div className="col">
                            <div className="grid-prod-price">£{item.variations[0].selling_price}</div>
                            <div className="grid-prod-qty">/{item.variations[0].current_batch.weight_unit}</div>
                        </div>
                        <div class="col p-0 text-right">
                        { Object.keys(item.variations).length>1 ? "": <AddtoCart1 product_qty={0} />}
                        </div>
                        </div>
                    </div>              
                </div>
            </div> 
            ))} 
        </div>
        </div>
    </section>

    );
};

export default Product;

import React from "react";
import {Link} from 'react-router-dom';

const SideMenu = (props) =>{
return(
    <div className="col-md-4 hide-mobile">
            <a href="My-Orders.html" className="side-link">
              My Orders
            </a>{" "}
            <a href="My-Addresses.html" className="side-link">
              My addresses
            </a>{" "}
            <Link to="/account" className="side-link active">
              My account
            </Link>{" "}
            <a href="Help.html" className="side-link">
              Help
            </a>{" "}
            <a href="Terms-and-conditions.html" className="side-link">
              Terms and conditions
            </a>{" "}
            <a href="Privacy-policy.html" className="side-link">
              Privacy policy
            </a>{" "}
            <a href="Login.html" className="side-link">
              Log out
            </a>  
          </div>
)
}
export default SideMenu;
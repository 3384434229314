import React from "react";

const Footer =()=>{
    return(
        <div>
            <footer class="">
  <div class="container pt-5 mt-5">
    <div class="row">
      <div class="col-md-2"> <img src="images/logo.svg" height="60" title="OJA Logo" /> </div>
      <div class="col-md-2">
        <h5>Company</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">About us</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Website Terms of Use</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Privacy and Cookie Policy</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Investors</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Reviews</a></li>
        </ul>
      </div>
      <div class="col-md-2">
        <h5>Customer Service</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Track your order</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Contact us</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Delivery</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Returns</a></li>
        </ul>
      </div>
      <div class="col-md-2">
        <h5>Social Media</h5>
        <ul class="nav flex-column">
          <p class="nav-item mb-2">Follow us:</p>
          <p class="nav-item mb-2"><a href="#" class="mr-3 soclal-link"><i class="bi fs-2 bi-instagram"></i></a> <a href="#" class="soclal-link"><i class="bi fs-2 bi-twitter"></i></a></p>
        </ul>
      </div>
      <div class="col-md-4">
        <form>
          <h3>Delivered straight to your inbox</h3>
          <p class="text-muted">Only news & updates, no spam, we promise</p>
          <div class="d-flex w-100 gap-2">
            <div class="input-group mb-0 email-footer">
              <input type="text" class="form-control" placeholder="Email Address" aria-label="Recipient's username" aria-describedby="button-addon2"/>
              <button class="btn btn-light" type="button" id="button-addon2"><img src="images/mail-button.png" height="16" title="Search-icon"/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="py-3 border-top text-center fw-bold"> <span class="copyright-text">©2021 Oja. All rights reserved.</span> </div>
  </div>
</footer>
        </div>
    )
}

export default Footer;
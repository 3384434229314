import api from "../../config/ApiConfig";
import { Apis } from "../../config";
import axios from "axios";

// import { NotificationManager } from 'react-notifications';

 const getPopularProduct = async (sellerId) => {
  let data = { seller_id: `${sellerId}` };
  let userDetails = JSON.parse(localStorage.getItem('userDetails'));
  let authToken = (userDetails!=null? userDetails.auth_token : null );
  try {    
      let result = (authToken)? await api.post(Apis.GetPopularProduct,data) : await axios.post(Apis.GetPopularProduct,data);
      if (result) {
          // NotificationManager.error(result.data.error,//axios//api);
          return result;
      }
      // return result;
  } catch (error) {
      console.log(error);
      return null;
  }
};

const getProductById = async (productId) => {
  // let data = { seller_id: `${productId}` };
  try {
      // let result = await axios.get(Apis.GetPopularProduct);
      let result = await axios.get(Apis.GetProductById+`/${productId}`);

      if (result) {
          // NotificationManager.error(result.data.error,//axios//api);
          return result;
      }
      // return result;
  } catch (error) {
      console.log(error);
      return null;
  }
};

export default {
  getPopularProduct,
  getProductById
};

import api from "../../config/ApiConfig";
import { Apis } from "../../config";
import axios from "axios";

const getProductDetail = async (productId) => {  
  try {
      let result = await api.get(Apis.GetProductDetail+productId);
      if (result) {
          // NotificationManager.error(result.data.error,//axios//api);
          return result;
      }
      // return result;
  } catch (error) {
      console.log(error);
      return null;
  }
};

export default {
    getProductDetail,
};

import api from "../../config/ApiConfig";
import { Apis } from "../../config";
import axios from "axios";
// import { NotificationManager } from 'react-notifications';

const getRelatedProducts = async (sellet_id,cat_id,product_id) => {
  let data = { seller_id: `${sellet_id}`, cat_id: `${cat_id}`, product_id: `${product_id}` };
  try {
      let result = await axios.post(Apis.GetRelatedProducts, data);
      if (result) {
          // NotificationManager.error(result.data.error,//axios//api);
          return result;
      }
      // return result;
  } catch (error) {
      console.log(error);
      return null;
  }
};

export default {
    getRelatedProducts,
};

import { USER_DATA } from "../actions/types";

export const userReducer = (
  state = { userProfile: JSON.parse(localStorage.getItem("userProfile") || "[]")},
  action
) => {
  switch (action.type) {
    case USER_DATA:
      return { userProfile: action.payload.userProfile };

    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { AddOrUpdateCart } from "../../services";


import {
  addToCart,
  removeFromCart,
  incrementToCart,
  productCount,
  decreaseToCart,
} from "../../store/actions/cartActions";

const AddToCart = (props) => {
  let { product, cartItems, productId = null, quantity,variation_id } = props;
  let {
    product_name,
    product_id,
    primary_image,
    product_mrp,
    product_qty,
    sku_id,
    cart,
  } = product;
  // console.log("props",product_name);
  // console.log("product_name",product_name);
  // console.log("primary_image",primary_image);
  // console.log("product_id",product_id);
  // console.log("product",product);
  console.log("cartItems", cartItems);

  const dispatch = useDispatch();

  const [count, setCount] = useState(product_qty);
  const changeBtn = async (count) => {
    if(productId != null){
      window.open(`/product/${productId}`, '_self')
    }
    ////call addOrUpdateCart

    
      const res = await AddOrUpdateCart.addOrUpdateCart(
        count,
        variation_id
      );
      if (res) {
        console.log("addOrUpdateCart", res.data.data.cart_count);
        // dispatch(incrementToCart(res.data.data.cart_count));
        dispatch(productCount(res.data.data.cart_count));
      } else {
        console.log("error");
      }
    
   



    //  await dispatch(addToCart(product,sku_id,productQty));
    await dispatch(addToCart(product, count, product_id));
  };

  // useEffect(()=>{
  //   if(productId != null){
  //     onClick(window.open('/','_self'))
      
  //   }
  // },[])
  return (
    <div>
      {quantity === 0 ? (
          <button
            onClick={() => {
              changeBtn(count + 1);
            }}
            type="button"
            className="btn btn-default btn-sm"
          >
            <i className="mdi mdi-cart-outline" />
            Add
          </button>
        
      ) : (
        
          <div className="input-group mb-3 pro-amt">
            {quantity === 1 ? (
              <button
                type="button"
                className="input-group-text"
                onClick={() => {              
                  // Remove_From_Cart(product.cart.id);
                }}
              >
                <i className="bi bi-dash"></i>
              </button>
            ) : (
              <button
                type="button"
                className="input-group-text"
                onClick={() => {
                  // AddOrUpdate(product.cart.quantity-1);
                }}
              >
                <i className="bi bi-dash"></i>
              </button>
            )}

            <input type="text" className="form-control" value={quantity} />

            <button
              type="button"
              className="input-group-text"
              onClick={() => {              
                // AddOrUpdate(product.cart.quantity+1);
              }}
            >
              <i className="bi bi-plus"></i>
            </button>
          </div>
        
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
  userProfile: state.user.userProfile,
});

export default connect(mapStateToProps, {
  addToCart,
  incrementToCart,
  decreaseToCart,
  removeFromCart,
  productCount
})(AddToCart);

import api from '../../config/ApiConfig';
import { Apis } from '../../config';
import axios from 'axios'
// import { NotificationManager } from 'react-notifications';

const getCategoryList = async (sellerId) => {
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    let authToken = (userDetails!=null? userDetails.auth_token : null );

    try {
        
        let result = (authToken)? await api.get(Apis.GetCategoryList+sellerId):await axios.get(Apis.GetCategoryList+sellerId);
        if (result) {
            // NotificationManager.error(result.data.error);
            return result;
        }
        // return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default {
    getCategoryList,
};
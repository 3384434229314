import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { GetCulturesList } from "../../services";
import MyCart from "../cart/MyCart";

const Header = (props) => {

  const [culturesList, setCulturesList] = useState([]);
  const [email,setEmail] = useState(null);

  // const refreshPage = ()=>{
  //   window.location.reload();
  // }
  
  
  useEffect(() => {
    setEmail(localStorage.getItem('loginEmail') ? localStorage.getItem('loginEmail') : "")
    async function culturesList() {
      const res = await GetCulturesList.getCulturesList();
      if (res) {
        console.log("culturesList", res);
        setCulturesList(res.data.data.cultures.data);
        await localStorage.setItem(
          "culturesList",
          JSON.stringify(res.data.data.cultures.data)
        );
      } else {
        console.log("error");
      }
    }
    culturesList();
  }, []);
  return (
    <header className="bg-default">
      <div className="top-bar-promo">Promo code prompt</div>
      <nav
        className="navbar navbar-expand-md flex-nowrap"
        aria-label="Third navbar example"
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvas"
            aria-controls="offcanvas"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-list"></i>
          </button>
          <Link className="navbar-brand" to="/">
            <img src="/images/logo.svg" height="50" title="OJA Logo" />
          </Link>
          <div className="top-nav-bar">
            <div className="me-auto mb-2 mb-sm-0 w-100 hide-mobile">
              <div className="input-group mb-0 px-5 top-nav-search">
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />
                <button
                  className="btn btn-light"
                  type="button"
                  id="button-addon2"
                >
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
            <div className="top-nav-bar-inner">
              <div className="cuisine-drop-div">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary cuisine-drop dropdown-toggle btn-sm"
                    type="button"
                    id="cuisine-drop"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    African Cuisine
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="cuisine-drop">
                    {culturesList.length > 0 &&
                      culturesList.map((culture, index) => (
                        <li key={index}>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-check"></i> {culture.name}{" "}
                            Cuisine
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="btn-group login-drop hide-mobile">
                {
                email ? 
                (<button type="button" className="btn btn-user-login"  data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fs-5 bi-person mr-3"></i>{" "}
                  <span className="ms-1 ">Welcome back!</span>
                </button>) : 
                (
                  <Link to={"/login"} className="btn btn-user-login">
                  <i className="fs-5 bi-person mr-3"></i>{" "}
                  <span className="ms-1 ">Log in / Sign up</span>
                  </Link>
                )
                }
                <ul className="dropdown-menu">
                  {/* <li>
                    <Link to={"/login"} className="dropdown-item">
                      <i className="fs-5 bi-box-arrow-in-right mr-3"></i>
                      <span className="ms-1 ">Log in / Sign up</span>{" "}
                    </Link>{" "}
                  </li> */}
                  <li>
                    <Link to={'my_order'} className="dropdown-item">
                      <i className="fs-5 bi-box-seam mr-3"></i>
                      <span className="ms-1 ">My orders</span>{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to="/my_account" className="dropdown-item">
                      <i className="fs-5 bi-person mr-3"></i>
                      <span className="ms-1 ">Account</span>{" "}
                    </Link>{" "}
                    <NavLink to="/address" className="dropdown-item">
                      <i className="fs-5 bi-person mr-3"></i>
                      <span className="ms-1 ">Address</span>{" "}
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a href="#" className="dropdown-item">
                      <i className="fs-5 bi-heart mr-3"></i>
                      <span className="ms-1 ">Favourites</span>{" "}
                    </a>{" "}
                  </li>
                  <li>
                    <a href="#" className="dropdown-item">
                      <i className="fs-5 bi-gear mr-3"></i>
                      <span className="ms-1 ">Settings</span>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="#" className="dropdown-item">
                      <i className="fs-5 bi-question-circle mr-3"></i>
                      <span className="ms-1 ">Help center</span>
                    </a>{" "}
                  </li>
                  {/* <li>
                <hr className="dropdown-divider" ></hr>
              </li> */}
                  <li>
                    <Link to={"/logout"} className="dropdown-item">
                      Log out
                    </Link>
                  </li>
                </ul>
              </div>
              
                {/* basket */}
              
             <MyCart />
               
                {/* basket */}

            
            
            </div>
          </div>
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-start w-75"
        tabindex="-1"
        id="offcanvas"
        data-bs-keyboard="false"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Welcome back
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body px-0">
          <ul
            className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
            id="menu"
          >
            <li className="nav-item">
              {" "}
              <a href="#" className="nav-link text-truncate">
                {" "}
                <i className="fs-5 bi-person mr-3"></i>
                <span className="ms-1 ">Log in / Sign up</span>{" "}
              </a>{" "}
            </li>
            <li>
              {" "}
              <a
                href="#submenu1"
                data-bs-toggle="collapse"
                className="nav-link text-truncate"
              >
                {" "}
                <i className="fs-5 bi-heart mr-3"></i>
                <span className="ms-1 ">Favourites</span>{" "}
              </a>{" "}
            </li>
            <li>
              {" "}
              <a href="#" className="nav-link text-truncate">
                {" "}
                <i className="fs-5 bi-gear mr-3"></i>
                <span className="ms-1 ">Settings</span>
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;

import axios from 'axios';
import { API_URL } from '../config';

let userDetails = JSON.parse(localStorage.getItem('userDetails'));
let authToken = (userDetails!=null? userDetails.auth_token : null );

// let authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYWY1YTk0MDlmYjBhM2NiNzM3ZmE1OTMxNWIzODAyMThjOWQ5MzBiNjc2MmUwOWZhOGU3ZjQ5ODM4NzI0M2YxODE2MzAzOGZkNzEyY2QyMTMiLCJpYXQiOjE2MzMwMTQxNDksIm5iZiI6MTYzMzAxNDE0OSwiZXhwIjoxNjQ4NjUyNTQ5LCJzdWIiOiIyNDgiLCJzY29wZXMiOltdfQ.ZJiN7C4M6u04I7OaOUinKRUF7ECQG_vccaZWO-DvXEyPRkTEwFeoDp_aeeQJEiQx-ADHycfIFlKkrAy4xyrUWcCSaFvk8O1iQX4xVl8PLIb-SVtogYM4rIsUu0tLnRwyBkT999KlqJgPF5QGTfMIMEsw0znmBK5Ivir-XOMTHL3eVQlUJy2Z6-phaL62TGUGXEjb75gU9tgOfvbCVX75SMMfq3aGCTDrCg62OZGp67bng7rM8yp-gD_xdG2erFgTGESAP7zMRl8ZzRCl8tu7CZra-E0evpmSUKPbugV7M1a8jT-ZRe7R8G3I_BPVvoj018dKI01joXy0-g-l0SUacTHisM6u21CW8f2S8Yya08RhbGoItQwi8dQ9Vz2yzsMfHxHXRDOESloq5_PRtk9ESJCT6y43jUWQ-AE9S7b85KIonJt2-FpflDOxVW2O632W3huRPVVth8iGml8028Q52RaFQCriHZjCp9JFI9bHj51Lu4UvXi1uFWfheEcmdao9Hhvji9d0yet2jHnaBiNdDZLOIyEl4wleZSJLQ_9L2Ttx6CVxAkvVOyMgyQXzHEp3hLrFV2B9vqgu78O_0R6ijF1aRCLWFpJQPxFFFh2VmfNP4M9xnxh4nNyq2BNU3xoqiQYkvgqjSVM2PiZ26sYdx9lXaLcpFodq4obNy09UF3g";
export default axios.create({
    //baseURL : "API_URL",
headers :{
    'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Headers":"*",
    // "Content-Type": "application/x-www-form-urlencoded",
    // "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZWUyN2Y0MmVjMDJkZmFkMmI1YTg3ZTJmZGEwNTYwZDI2MTZlMTkwOTc5YTMwYTIxNWZmMjFjZmU3ZWE2MGM2Y2JhYjdiOWQ1MjUxMDQ3NDUiLCJpYXQiOjE2MzI4MDc1OTQsIm5iZiI6MTYzMjgwNzU5NCwiZXhwIjoxNjQ4NDQ1OTk0LCJzdWIiOiI0ODEiLCJzY29wZXMiOltdfQ.DER_z90kZjt4K47ZhRN2tiK_7nbJ7MM-c6hMWkfhQ5OR2VOYoqgf4Tbqv3t5SZYotcSckjwrjMBtNtXrzbzaTMgQTOmvlKmdfbkxBLRBcXgGHiQt3IWJmyT-YcMgl9lfI8WcFVBy9Mc84MCTF0zz8PxCwsPB5kNUn_G8y5qwg-e2hqc6zqFfycLSdr6ZuqMsdujiPkY82Hh5DKm4hmbi8eZ02W4HC3gBr6v1457pVq7HOKyD8K0TFahCejGzoWY1ebeowysc7W_KhtkMcrUWbLcaT6c3GrGUYA-YrdcgH0ANMAweiEA_Gad9ZCNo09_EbQ4FEO_2hvo_Te6HpUQBlD0Xa4FHwR6xNPRI7_nTFtD1zSKIm6vV_IuXDDV75NpxUse_UDyrl5i1Hyhf5mC7b9Jq89CFUsgAdjeilDqFW77eMA_yJbylhCsacrjfXf7ru_TnrCqbAtAdDnPDjiogZh91zq8c_w5I0_oSvLsVCO8ExCbdZBjQVXpzPJSwyhbqo4SoemzSKTNrMYOBUwPd15cxzQ94VyJ32SdXnbUA5rlA1nQR3LV5e1Y23x-XG1qGXWYqntz4WxkbNEGSEx9Qwhw4OqmIuUb8DWlM618jO5yjn3uosGTM5PJFaHDSCZFn3MWdAWFe0WgR3c1k3Udobr1HQ1YD3_YQ_ldx7I8xGcA",
    'Accept': 'application/json', 
    'Authorization': 'Bearer '+authToken,
    'Content-Type': 'application/json',
    //'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
}


})

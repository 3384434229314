import api from '../../config/ApiConfig';
import { Apis } from '../../config';
import axios from 'axios'


const checkDeliveryPostcode = async (postcode) => {

    let data = {
                "latitude": "51.516153",
                "postcode": postcode,
                "seller_id": "44",
                "longitude": "0.021564"
              };
    
    try {
        let result = await axios.post(Apis.CheckDeliveryPostcode,data);
        if (result) {
            // NotificationManager.error("error","message");
            return result;
        }
        // return result;
    } catch (error) {
        console.log(error);
        return null;
    }
  };
  

export default {
    checkDeliveryPostcode,
};
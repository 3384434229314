import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  removeFromCart,
  incrementToCart,
  decreaseToCart,
} from "../../store/actions/cartActions";
import { Link } from "react-router-dom";
import {GetMyCartData } from "../../services";

const MyCart = (props) => {
  const { cartItems } = props;
  const dispatch = useDispatch();
  const [ cartVisiable,setCartVisiable] = useState("")

  ////////////////\\\\\\\\\\\\\\\
  const [cartData, setCartData] = useState({});
  let auth_token = localStorage.getItem('userDetails');
  useEffect(()=>{
    async function getMyCart() {
      const res = await GetMyCartData.getMyCartData();
      if (res) {
        console.log("getMyCart",res.data.data)
        setCartData(res.data.data);
        // await localStorage.setItem(
        //   "allCategories",
        //   JSON.stringify(res.data.data.category)
        // );
      } else {
        console.log("error");
      }
    }
    if(auth_token){
      getMyCart()
    }

  },[])
  /////////\\\\\\\\\\\\\\\
  return (
    <div>
      {console.log(cartItems)}
      <div className="btn-group cart-group">
        <button
          // className="cart-button dropdown-toggle"
          className="cart-button "
          onClick={()=>setCartVisiable("show")}
          type="button"
          // data-bs-toggle="dropdown"
          // data-bs-toggle="dropdown"

          // aria-expanded="false"
        >
          <i className="bi bi-bag-fill"></i>
          <span className="cart-value">
            {cartData.cart_count ? cartData.cart_count : 0}

          </span>
        </button>

        <div className={`dropdown-menu ${cartVisiable ? cartVisiable : null}`}>
          <div className="cart-list container">
            <div className="row mt-2">
              <div className="col-8">
                <h5 className="heading-colored text-uppercase">My Basket</h5>
              </div>
              <div className="col-4 text-right" onClick={()=>setCartVisiable("")}>
                {" "}
                <p style={{cursor:"pointer"}} onClick={()=>setCartVisiable(false)}>Close</p>{" "}
              </div>
            </div>
            <div className="border-bottom-separator"></div>
            
            { cartData.my_cart != undefined && cartData.my_cart.data.map((item,index)=>(
              <>
                <div className="product-widget row align-items-center">
                  <div className="product-img col-2 pe-0">
                    <img
                      src={item.variation.images[0].name}
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="product-body col-4 px-2">
                    <div className="product-name">{item.variation.product.name}</div>
                    <div className="product-qty text-muted">
                      <span className="qty" />
                      Quantity 
                      {item.variation.product.weight} {item.variation.product.weight_unit}
                    </div>
                  </div>
                  <div className="product-body col-2 p-0">
                    <div className="input-group pro-amt">
                      <button
                        type="button"
                        className="input-group-text"
                        // onClick={() => dispatch(decreaseToCart(item))}
                      >
                        <i className="bi bi-dash"></i>
                      </button>
                      <input
                        type="text"
                        className="form-control"
                        value={item.quantity}
                      />

                      <button
                        type="button"
                        className="input-group-text"
                        // onClick={() => dispatch(incrementToCart(item))}
                      >
                        <i className="bi bi-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div className="product-body prod-pri text-center col-2 p-0">
                    {" "}
                    £{item.variation.selling_price}
                  </div>
                  <div className="product-delet col-2">
                    <button
                      className="delete"
                      // onClick={() => dispatch(removeFromCart(item))}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
                <div className="border-bottom-separator"></div>
              </>
            ))}

            <div className="cart-summary row mb-2">
              <div className="col-7">
                <p className="m-0 text-muted">Basket total</p>
              </div>
              <div className="col-5">
                <p className="text-right m-0">£
                {cartData.discount ? cartData.discount.netAmountBeforeDiscount : null}
                
                      </p>
              </div>
            </div>
            <div className="cart-summary row mb-3">
              <div className="col-7">
                <p className="m-0 text-muted">Delivery fee</p>
              </div>
              <div className="col-5">
                <p className="text-right m-0">£
                {cartData.discount ? cartData.discount.deliveryCharge : null}

                </p>
              </div>
            </div>
            <div className="border-bottom-separator"></div>
            <div className="cart-summary row mb-2">
              <div className="col">
                <p className="m-0 text-muted">Promo code</p>
              </div>
            </div>
            <div className="apply-promo row mb-3">
              <div className="col-7 pe-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter promocode here"
                />
              </div>
              <div className="col-5">
                <button type="button" className="btn btn-outline-dark w-100">
                  Apply
                </button>
              </div>
            </div>
            <div className="border-bottom-separator"></div>
            <div className="cart-summary row mb-2">
              <div className="col-7">
                <p className="m-0 text-muted">Promo Discount</p>
              </div>
              <div className="col-5">
                <p className="text-right m-0">£0</p>
              </div>
            </div>
            <div className="cart-summary row mb-4">
              <div className="col-7">
                <h6 className="m-0">Total ({cartData.cart_count} items):</h6>
              </div>
              <div className="col-5">
                <h6 className="text-right m-0">
                  <strong>
                    £
                    {cartData.discount ? cartData.discount.netAmountAfterDiscount : null}
                    
                  </strong>{" "}
                </h6>
              </div>
            </div>
          </div>
          <div className="cart-footer container">
            <div className="cart-btns row">
              {/* <div className="col-12 mb-2" onClick={()=>setCartVisiable("")}>
                <Link to="/checkout" className="btn btn-default text-uppercase w-100">
                  Checkout
                </Link>{" "}
              </div> */}
              <div className="col-12 mb-2" onClick={()=>setCartVisiable("")}>
                <Link to="/checkout" className="btn btn-default text-uppercase w-100">
                  Checkout
                </Link>{" "}
              </div>
              <div className="col-12 mb-3" onClick={()=>setCartVisiable("")}>
                <Link
                  to={"/basket"}
                  className="btn btn-outline-dark text-uppercase w-100"
                >
                  View Basket
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default connect(
//   (state) => ({
//     cartItems: state.cart.cartItems,
//   }),
//   { incrementToCart, decreaseToCart, removeFromCart }
// )(MyCart);

export default MyCart;

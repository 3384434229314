import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { object } from "yup";
import AddtoCart from "../comman/AddToCart";
import { Apis } from "../../config";
import ApiConfig from "../../config/ApiConfig";
import { GetPopularProduct} from "../../services";
import Procount from "../comman/Procount";


const Product = (props) => {
  
  const [propularProduct, setPopularProduct] = useState([]);
  useEffect(() => {
    async function popularProducts() {
      const res = await GetPopularProduct.getPopularProduct(44);
      if (res) {
        console.log("popularProducts", res);
        setPopularProduct(res.data.data.popular_products);
        await localStorage.setItem("popularProducts", JSON.stringify(res.data));
      } else {
        console.log("error");
      }
    }
    popularProducts();
  }, []);

  async function popularProducts() {
    const res = await GetPopularProduct.getPopularProduct(44);
    if (res) {
      // console.log("popularProducts", res);
      setPopularProduct(res.data.data.popular_products);
      await localStorage.setItem("popularProducts", JSON.stringify(res.data));
    } else {
      console.log("error");
    }
  }

  let userDetails = JSON.parse(localStorage.getItem('userDetails'));
  let authToken = (userDetails!=null? userDetails.auth_token : null );
 
  const AddOrUpdate = async(quantity,variation_id) =>{    
    if(!authToken){
      window.open('/login', '_self');
    }else{      
      let data = { quantity: quantity, variation_id: `${variation_id}`,overide_cart:"" };
      const res = await ApiConfig.post(Apis.AddOrUpdateCart,data);
      if (res) {    
        popularProducts();    
        <Procount pro_count={5} />
        console.log("add-to-cart-res", res);
      } else {
        console.log("error");
      }
    }
  }
  const Remove_From_Cart = async(cart_id) =>{
    if(!authToken){
      window.open('/login', '_self');
    }else{      
      let data = { cart_id: cart_id };
      const res = await ApiConfig.post(Apis.RemoveFromMyCart,data);
      if (res) {
        popularProducts();
        console.log("Remove From Cart", res);
      } else {
        console.log("error");
      }
    }
  }

  console.log(propularProduct);
  return (
    <section className="Popular-Products">
      <div className="container py-5">
        <div className="row mb-4">
          <div className="col-12 page-head-with-more">
            <h2 className="heading-colored fw-bold text-uppercase m-0">
              Popular Products
              <span className="text-sub-head">
                Browse what Oja shoppers are loving this week...
              </span>
            </h2>
            <button type="button" className="btn btn-link view-more-but">
              More <i className="bi bi-chevron-right"></i>
            </button>
          </div>
        </div>
        <div className="row row-cols-2 row-cols-md-5 g-4 product-grid-6">
          {propularProduct.length > 0 &&
            propularProduct.map((item, index) => (
              <div className="col" key={index}>
                {index >=0 ?
                <div className="card">
                <Link
                  to={{
                    pathname: `/product/`+item.id,
                    state: {
                      product: item,
                      productId: item.id,
                    },
                  }}
                >
                  <img
                    src={item.image}
                    className="card-img-top"
                    alt="Product Name"
                  />
                  </Link>
                  <div className="card-body">
                    <p className="card-title">                     
                    <Link
                        to={{
                          pathname: `/product/`+item.id,
                          state: {
                            product: item,
                            productId: item.id,
                          },
                        }}
                      >
                        {item.name}
                      </Link>
                    </p>
                  </div>

                  <div className="card-footer">
                    <div className="row">
                      <div className="col">
                        <div className="grid-prod-price">£{item.variations[0].price_with_tax}</div>
                        <div className="grid-prod-qty">/{item.variations[0].current_batch.weight_unit}</div>
                      </div>
                      <div class="col p-0 text-right">
                      { Object.keys(item.variations).length>1 ? 
                      <Link
                        className="btn btn-default btn-sm"
                        to={{
                          pathname: `/product/`+item.id,
                          state: {
                            product: item,
                            productId: item.id,
                          },
                        }}
                      >
                        Add
                      </Link>
                      : 
                      // <AddtoCart 
                      //     product_qty={0}
                      //     product={{
                      //       product_name: item.name,
                      //       product_id : item.id,
                      //       primary_image: item.image,
                      //       product_mrp: item.variations[0].current_batch.product_mrp,
                      //       product_qty: item.variations[0].current_batch.product_qty,
                      //       sku_id: item.variations[0].sku,
                      //       variation_id: item.variations[0].id,
                      //       cart: item.variations[0].cart
                      //     }}
                      // />

                      <div className="col p-0 text-right">
     
                        {item.variations[0].cart==null ? (
                          
                            <button
                              onClick={() => {              
                                AddOrUpdate(1,item.variations[0].id);
                              }}
                              type="button"
                              className="btn btn-default btn-sm"
                            >
                              <i className="mdi mdi-cart-outline" />
                              Add
                            </button>
                          
                        ) : (
                          
                            <div className="input-group mb-3 pro-amt">
                              {item.variations[0].cart.quantity === 1 ? (
                                <button
                                  type="button"
                                  className="input-group-text"
                                  onClick={() => {              
                                    Remove_From_Cart(item.variations[0].cart.id);
                                  }}
                                >
                                  <i className="bi bi-dash"></i>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="input-group-text"
                                  onClick={() => {
                                    AddOrUpdate(item.variations[0].cart.quantity-1,item.variations[0].id);
                                  }}
                                >
                                  <i className="bi bi-dash"></i>
                                </button>
                              )}

                              <input type="text" className="form-control"  value={item.variations[0].cart.quantity} />

                              <button
                                type="button"
                                className="input-group-text"
                                onClick={() => {              
                                  AddOrUpdate(item.variations[0].cart.quantity+1,item.variations[0].id);
                                }}
                              >
                                <i className="bi bi-plus"></i>
                              </button>
                            </div>
                          
                        )}
                      </div>

                      
                        
                      }
                      {console.log(item.variations[0].id)}
                      </div>
                    </div>
                  </div>
                </div>
                :null}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Product;

import React, { useEffect, useState } from "react";

import AddToCart from "../comman/AddToCart";
import AddtoCart1 from "../comman/AddToCart1";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  removeFromCart,
  incrementToCart,
  decreaseToCart,
} from "../../store/actions/cartActions";
import { Link } from "react-router-dom";
import EmptyBasket from "./EmptyBasket";
import { GetMyCartData } from "../../services";

const Basket = (props) => {
  const { cartItems } = props;
  const dispatch = useDispatch();

  

  ////////////////\\\\\\\\\\\\\\\
  const [cartData, setCartData] = useState();
  const [cartAmount,setCartAmount] = useState({});
  let auth_token = localStorage.getItem('userDetails');
  useEffect(()=>{
    async function getMyCart() {
      const res = await GetMyCartData.getMyCartData();
      // const res = await GetCategoryList.getCategoryList(sellerId);
      if (res) {
        // console.log("getMyCart",res.data.data)
        setCartData(res.data.data);
        setCartAmount(res.data.data.discount)
        // await localStorage.setItem(
        //   "allCategories",
        //   JSON.stringify(res.data.data.category)
        // );
      } else {
        console.log("error");
      }
    }
    if(auth_token){
      getMyCart()
    }

  },[])
  /////////\\\\\\\\\\\\\\\

  return (
    <div>
      {/* {console.log("cartData", cartData.my_cart.data.map((item,index)=>(item.id)))} */}
    {cartData != undefined ?
    <div>
      <section className="section-breadcrumb">
        <div className="container py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={"/"}>Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Link to={"/basket"}>Basket</Link>
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="Product-Details">
        <div className="container py-3">
          <div className="row mb-4">
            <div className="col-12 page-head-with-more">
              <h2 className="heading-colored fw-bold text-uppercase m-0">
                basket
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-8">
              <h6 className="fw-bold text-uppercase border-bottom border-2 pb-2 mb-4">
                Product Details
              </h6>

             { cartData.my_cart.data.map((item,index)=>

               <div>
                 {/* <h1>{item.id}</h1> */}
                  <div className="product-checkout row align-items-center">
                    <div className="product-img col-2 pe-0">
                     
                      <img
                        src={item.variation.images[0].name}
                        className="img-fluid"
                        width="58"
                        alt=""
                      />
                    </div>
                    <div className="col-4 px-2">
                      <div className="product-name">
                        {/* <a href="#"> */}
                          {item.variation.product.name}
                          {/* </a> */}
                      </div>
                      <div className="product-qty text-muted">
                        <span className="qty">
                          Quantity {" "}
                        {item.variation.product.weight} {item.variation.product.weight_unit}
                        </span>
                      </div>
                    </div>
                    <div className="col-2 p-0">
                      <div className="input-group pro-qty-control">
                        <button
                          type="button"
                          className="input-group-text"
                          // onClick={() => dispatch(decreaseToCart(item))}
                        >
                          <i className="bi bi-dash"></i>
                        </button>
                        <input
                          type="text"
                          className="form-control"
                          value={item.quantity}
                        />
                        <button
                          type="button"
                          className="input-group-text"
                          // onClick={() => dispatch(incrementToCart(item))}
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="prod-pri text-center col-2 p-0">
                      {" "}
                      £ {item.variation.selling_price}
                    </div>
                    <div className="product-delet col-2 text-center">
                      <button className="delete"
                      //  onClick={() => dispatch(removeFromCart(item))}
                       >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  </div>
                  <div className="border-bottom-separator"></div>
                </div>
             )}
   
             
            </div>


            <div className="col-md-4">
              <h6 className="fw-bold text-uppercase border-bottom border-2 pb-2 mb-4">
                order Summary
              </h6>
              <div className="row mb-2">
                <div className="col-7">
                  <p className="m-0 text-muted">Delivery date</p>
                </div>
                <div className="col-5">
                  <h6 className="text-right m-0">
                    <button
                      type="button"
                      className="btn btn-link text-warning p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#DeliveryDateModal"
                    >
                      Wed, Aug 18 <i className="bi bi-calendar-week ms-2"></i>
                    </button>
                  </h6>
                </div>
              </div>
              <div className="border-bottom-separator mt-2"></div>
              <div className="row mb-2">
                <div className="col-7">
                  <p className="m-0 text-muted">Basket total</p>
                </div>
                <div className="col-5">
                  <p className="text-right m-0">£
                  {cartAmount.basketItemsValue}
                  {/* <Total discount={0}/> */}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-7">
                  <p className="m-0 text-muted">Delivery fee</p>
                </div>
                <div className="col-5">
                  <p className="text-right m-0">£
                  {cartAmount.deliveryCharge}
                  
                  </p>
                </div>
              </div>
              <div className="border-bottom-separator mt-2"></div>

              <div className="row mb-2">
                <div className="col">
                  <p className="m-0 text-muted">Promo code</p>
                </div>
              </div>
              <div className="apply-promo row mb-3">
                <div className="col-7 pe-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter promocode here"
                  />
                </div>
                <div className="col-5">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100 text-uppercase"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div className="border-bottom-separator mt-3"></div>

              <div className="row mb-2">
                <div className="col-7">
                  <p className="m-0 text-muted">Promo Discount</p>
                </div>
                <div className="col-5">
                  <p className="text-right m-0">£ 0</p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-7">
                  <h6 className="m-0 fw-bold checkout-total">
                    Total ({cartData.cart_count} items):
                  </h6>
                </div>
                <div className="col-5">
                  <h6 className="text-right m-0 fw-bold checkout-total">
                    £ {cartAmount.netAmountAfterDiscount}
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-2">
                  <Link to="/checkout" className="btn btn-default text-uppercase w-100">
                    Checkout
                  </Link>{" "}
                </div>
                <div className="col-12 mb-3 text-center fs-12 text-muted-2">
                  🔒 Magicaly secured text to make all security concerns go
                  away.
                </div>
              </div>
            </div>
          </div>
          <div className="border-bottom-separator"></div>
        </div>
      </section>
{/* 
      <section className="order-again">
        <div className="container py-3">
          <div className="row mb-3">
            <div className="col-12 page-head-with-more">
              <h2 className="fw-bold heading-colored text-uppercase m-0">
                You may also like
              </h2>
            </div>
          </div>
          <div className="row product-grid-6">
            <div className="col">
              <div className="owl-carousel owl-theme" id="carousel-example">
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product01.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product02.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product03.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product04.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product05.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product06.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product01.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product02.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product03.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product04.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product05.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product06.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      */}
    </div>

:

<EmptyBasket />
                        }
        <div
        className="modal fade"
        id="DeliveryDateModal"
        tabindex="-1"
        aria-labelledby="DeliveryDateModallLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h6
                className="modal-title text-uppercase fw-bold"
                id="DeliveryDateModalLabel"
              >
                Choose your delivery date
              </h6>
              <button
                type="button"
                className="btn btn-link btn-sm btn-pop-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
            <div className="modal-body pb-4">
              <div id="DeliveryDateSelect"> </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};
export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { incrementToCart, decreaseToCart, removeFromCart }
)(Basket);

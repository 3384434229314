import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Culture = (props) => {
  let list = JSON.parse(localStorage.getItem("culturesList"));
  const [culturesList, setCulturesList] = useState([list]);
  // ----------------load more ---------------
  const postsPerPage = 6;
  let arrayForHoldingPosts = [];
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(6);
  const [loadAll, setLoadAll] = useState(0);

  const loopWithSlice = (start, end) => {
    const slicedPosts = list.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow([...postsToShow, ...arrayForHoldingPosts]);
  };

  const handleShowMorePosts = () => {
    setLoadAll(1);

    loopWithSlice(next, next + list.length);
    setNext(next + postsPerPage);
  };
  // ----------------load more ---------------
useEffect(()=>{
  loopWithSlice(0, next)
},[])
  return (
    <div>
      <section className="caltures-banner">
        <div className="container-fluid">
          <div className="row align-items-center div-fullscreen">
            <div className="col-md-12 py-5 bg-black bg-opacity-50">
              <div className="row text-center">
                <div className="col-md-6 m-auto">
                  <h1 className="migra-font text-white">
                    New East African products
                  </h1>
                  <h5 className="fw-normal text-white my-4">
                    Relax, put your feet up and shop in more than
                    <br />
                    just the 'world foods aisle'...
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-breadcrumb">
        <div className="container py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
              <Link to="/culture">
                Culture
              </Link>
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="cuisines-list">
        <div className="container">
          <div className="row">
            <div className="col-12 page-head-with-more">
              <h2 className="heading-colored fw-bold text-uppercase my-3">
                cultures
              </h2>
            </div>
          </div>
          <div className="row row-cols-3 row-cols-md-6 g-4 wrap-label-row">
            {postsToShow.length > 0 &&
              postsToShow.map((culture, index) => (
                <div className="col">
                  {" "}
                  <img
                    src={culture.image}
                    width="75"
                    alt="cuisines"
                    className="rounded-4 w-100"
                    style={{ backgroundColor: "#333" }}
                  />
                  <p className="wrap-label" style={{ color: "#fff" }}>
                    {culture.name}
                  </p>
                </div>
              ))}
          </div>

         

          <div className="row">
            <div className="col-12 mt-4 text-center">
            {loadAll === 1 ? null : (
                <button
                  onClick={handleShowMorePosts}
                  type="button"
                  className="btn btn-outline-light px-5"
                >
                  Load all
                </button>
              )}
              {/* <button type="button" className="btn btn-outline-light px-5">
                Load all
              </button> */}
            </div>
          </div>


          <div className="row mt-5">
            <div className="col-12">
              <div className="vertical-scroll">
                <button type="button" className="btn btn-brown me-4">
                  Herbs, Spices & Seasoning
                </button>
                <button type="button" className="btn btn-light me-4">
                  Pasta, Rice & Noodles
                </button>
                <button type="button" className="btn btn-light me-4">
                  Flours & Powders
                </button>
                <button type="button" className="btn btn-light me-4">
                  Sauces & Condiments
                </button>
                <button type="button" className="btn btn-light me-4">
                  Baking
                </button>
                <button type="button" className="btn btn-light me-4">
                  Canned
                </button>
                <button type="button" className="btn btn-light me-4">
                  Packaged
                </button>
                <button type="button" className="btn btn-light me-4">
                  Baking
                </button>
                <button type="button" className="btn btn-light me-4">
                  Canned
                </button>
                <button type="button" className="btn btn-light me-4">
                  Packaged
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="section-products mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="border-bottom border-2"></div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 side-bar-filter hide-mobile">
              <h6 className="text-uppercase border-bottom border-2 text-muted-light py-3">
                Filter By
              </h6>
              <ul className="list-unstyled ps-0">
                <li className="mb-1 border-bottom">
                  <button
                    className="btn btn-toggle btn-toggle-plus"
                    data-bs-toggle="collapse"
                    data-bs-target="#culture-collapse"
                    aria-expanded="true"
                  >
                    {" "}
                    culture{" "}
                  </button>
                  <div className="collapse show" id="culture-collapse">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal small">
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 01</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 02</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 03</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 border-bottom">
                  <button
                    className="btn btn-toggle btn-toggle-plus collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#brand-collapse"
                    aria-expanded="false"
                  >
                    {" "}
                    Brand{" "}
                  </button>
                  <div className="collapse" id="brand-collapse">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal border-bottom small">
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          <span>Option 01</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          <span>Option 02</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          <span>Option 03</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 border-bottom">
                  <button
                    className="btn btn-toggle btn-toggle-plus collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#category-collapse"
                    aria-expanded="false"
                  >
                    {" "}
                    category{" "}
                  </button>
                  <div className="collapse" id="category-collapse">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal border-bottom small">
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="category-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 01</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="category-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 02</span>
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="category-filter"
                            type="radio"
                            value=""
                          />
                          <span>Option 03</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-10">
              <div className="row mb-3 align-items-center">
                <div className="col">
                  <h6 className="text-uppercase py-3 m-0">
                    <strong>1000</strong>{" "}
                    <span className="text-muted-light">Products</span>
                  </h6>
                </div>
                <div className="col text-right">
                  <h6 className="text-muted-light hide-mobile m-0">
                    SORT BY:
                    <select className="select-white border-0 text-dark">
                      <option value="0">Recommended</option>
                      <option value="0">Option 02</option>
                    </select>
                  </h6>
                  <button
                    type="button"
                    className="btn-light filter-bu-mobile hide-desktop"
                    data-bs-toggle="modal"
                    data-bs-target="#filterModel"
                  >
                    <i className="bi bi-filter"></i>
                  </button>
                </div>
                <div className="col-12">
                  <div className="border-bottom border-2"></div>
                </div>
              </div>
              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4 g-4 product-grid-6">
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product01.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product02.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <span className="wish-grid">
                      {/* <i className="bi bi-heart-fill"></i> */}
                    </span>
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product03.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <span className="wish-grid selected">
                      {/* <i className="bi bi-heart-fill"></i> */}
                    </span>
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product04.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product05.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product06.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product01.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product02.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product03.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product04.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product05.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product06.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product01.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product02.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product03.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product04.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0">
                          <div className="input-group mb-3 pro-amt">
                            <button type="button" className="input-group-text">
                              <i className="bi bi-dash"></i>
                            </button>
                            <input
                              type="text"
                              className="form-control"
                              value="1"
                            />
                            <button type="button" className="input-group-text">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product05.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product06.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product05.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    {" "}
                    <img
                      src="images/product06.png"
                      className="card-img-top"
                      alt="Product Name"
                    />
                    <div className="card-body">
                      <p className="card-title">
                        <a href="#">Product Name Two Different Lines</a>
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col">
                          <div className="grid-prod-price">£0.00</div>
                          <div className="grid-prod-qty">/kg</div>
                        </div>
                        <div className="col p-0 text-right">
                          <button className="btn btn-default btn-sm">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center border-bottom border-2 border-top py-3">
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Previous">
                          <i className="bi bi-chevron-left"></i>
                        </a>{" "}
                      </li>
                      <li className="page-item">
                        <a className="page-link active" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next">
                          <i className="bi bi-chevron-right"></i>
                        </a>{" "}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Filter Modal --> */}
      <div
        className="modal fade"
        id="filterModel"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header d-block">
              <div className="row">
                <div className="col-3">
                  <button
                    type="button"
                    className="btn btn-link btn-sm fw-normal"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 text-center">
                  <h5 className="modal-title" id="exampleModalLabel">
                    FILTER
                  </h5>
                </div>
                <div className="col-3 text-right">
                  <button
                    type="button"
                    className="btn btn-link btn-sm fw-normal"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body side-bar-filter">
              <div className="row py-3 border-bottom">
                <div className="col-12 text-nowrap overflow-scroll fw-bold">
                  <span className="small mr-3">Shot By:</span>
                  <button
                    type="button"
                    className="btn btn-outline-black btn-sm mr-3"
                  >
                    Recommended
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-black btn-sm mr-3"
                  >
                    Best Sellers
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-black btn-sm mr-3"
                  >
                    Newest
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-black btn-sm"
                  >
                    Oldest
                  </button>
                </div>
              </div>
              <ul className="list-unstyled ps-0">
                <li className="mb-1 border-bottom">
                  <button className="btn btn-toggle px-0 text-uppercase">
                    {" "}
                    culture{" "}
                  </button>
                  <div className="collapse show">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal small">
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          Option 01{" "}
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          Option 02{" "}
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="culture-filter"
                            type="radio"
                            value=""
                          />
                          Option 03{" "}
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 border-bottom">
                  <button className="btn btn-toggle px-0 text-uppercase">
                    {" "}
                    Brand{" "}
                  </button>
                  <div className="">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal small">
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          Option 01{" "}
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          Option 02{" "}
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                          />
                          Option 03{" "}
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1">
                  <button className="btn btn-toggle px-0 text-uppercase">
                    {" "}
                    category{" "}
                  </button>
                  <div className="">
                    <ul className="btn-toggle-nav pb-1 list-unstyled fw-normal small">
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="category-filter"
                            type="radio"
                            value=""
                          />
                          Option 01{" "}
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="category-filter"
                            type="radio"
                            value=""
                          />
                          Option 02{" "}
                        </label>
                      </li>
                      <li>
                        <label className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            name="category-filter"
                            type="radio"
                            value=""
                          />
                          Option 03{" "}
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default w-100">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Culture;

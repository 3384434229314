import React,{useState,useEffect}from 'react';
//import Button from 'react-bootstrap/Button';
import {Register} from '../services';
import facebook from '../images/facebook.png';
import google from '../images/google.png';
import apple from '../images/apple.png';
import { useHistory ,Link} from 'react-router-dom';
 

function Registration() {    

    const History = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const [msg , setMsg] = useState();
    const [email , setEmail] = useState();
    const [password , setPassword] = useState();
    const [device_token , setDevice_token] = useState("gdgdggdgdgdggd");
    const [country_code , setCountry_code] = useState(+91);
    const [phone , setPhone] = useState(9685746000);
    const [postcode, setPostcode] = useState();


    const submitForm = async (event)=>{
        event.preventDefault();
        const res = await Register.register(email,password,device_token, country_code,phone);
        if (res) {
            setMsg(res.data.message);
            if(res.data.status === 1){
                console.log(res.data.data.auth_token);
                localStorage.setItem('auth_token' , res.data.data.auth_token);
                console.log(res.data.data.email);
                localStorage.setItem('loginEmail' , res.data.data.email);
                History.push("/login");
            }
        } else {
            console.log("error");
        } 
    }
 
    return (
        <div>
            <div className="container py-5">
                <div className="row mb-3">
                    <div className="col-md-4 m-auto form-grey">
                        <h1 className="mb-4 text-center migra-font text-dark">Sign up</h1>
                        <div className="d-grid gap-2">
                            <button type="button" className="btn btn-dark btn btn-secondary"><img src={apple} className="mr-3" height="20" alt="" />Sign in with Apple</button>
                            <button type="button" className="btn btn-outline-blue  btn btn-light"><img src={facebook} className="mr-3" height="20" alt="" />Continue with Facebook</button>
                            <button type="button" className="btn btn-outline-black  btn btn-light"><img src={google} className="mr-3" height="20" alt="" />Continue with Google</button>
                            <p className="text-muted my-3 text-center">or continue with</p>
                            <form onSubmit={submitForm}>
                                <div className="mb-3">
                                    <input type="email" className="form-control" id="email" name="email" placeholder="name@emailaddress.com" onChange={(e)=> setEmail(e.target.value)} value={email} />
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" id="password" placeholder="Password" name="password" onChange={(e)=> setPassword(e.target.value)} value={password} />
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="postcode" placeholder="Postcode" name="postcode" onChange={(e)=> setPostcode(e.target.value)} value={postcode} />
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label text-muted" for="exampleCheck1">I want to recieve newsletter updates and discounts</label>
                                </div>
                                <div className="mt-4">
                                    <p style={{color:'red'}} className="m-0">{msg}</p>
                                    <button type="submit" className="btn btn-default text-uppercase w-100">Sign up</button>
                                    {/* <a href="index.html" className="btn btn-default text-uppercase w-100">Sign up</a> */}
                                </div>
                                <div className="mt-3">
                                    <p className="text-muted m-0 text-center" /><small>Already have an account?</small> <Link to={"/login-with-password"} className="link-warning px-2">Log in</Link>
                                </div>
                                <div className="mt-4 px-5 text-center small text-muted">
                                    By signing up you agree to our <a href=" " className="link-dark-bold">Terms of Service</a> and <a href=" " className="link-dark-bold">Privacy Policy</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Registration

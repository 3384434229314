import React,{useState,useEffect} from 'react'
import { GetMyAddressList } from '../services';
import HomeSetting from './HomeSetting'
import { Link } from "react-router-dom";
// import MyAccount from './MyAccount'

function MyAddress() {



  useEffect(() => {
    async function addressDetails() {
      const res = await GetMyAddressList.getMyAddressList();
      if (res) {
        console.log("addressDetailsList - ", res);
      } else {
        console.log("error");
      }
    }

    addressDetails();  
    
  });


                


              return (
                <div>
                  <div className="container py-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Settings</li>
                      </ol>
                    </nav>
                  </div>
                  <div className="container mb-4">
                    <div className="row mb-4">
                      <div className="col-12 page-head-with-more">
                        <h2 className="heading-colored fw-bold text-uppercase m-0">SETTINGS</h2>
                      </div>
                    </div>
                    <div className="row">
                        <HomeSetting></HomeSetting>
                        {/* <div className="col-md-4 hide-mobile">
                          <a href="My-Orders.html" className="side-link">My Orders</a> 
                          <a href="My-Addresses.html" className="side-link active">My addresses</a> 
                          <a href="My-Account.html" className="side-link">My account</a>
                          <a href="Help.html" className="side-link">Help</a> 
                          <a href="Terms-and-conditions.html" className="side-link">Terms and conditions</a> 
                          <a href="Privacy-policy.html" className="side-link">Privacy policy</a>
                          <a href="Login.html" className="side-link">Log out</a>
                        </div> */}
                        <div className="col-md-8">
                          <form className="gray-inout">
                            <p className="m-0 fw-bold text-uppercase">Add new address</p>
                              <div className="mb-3 mt-3">
                                <label for="enter-postcode" className="form-label">Post code</label>
                                <div className="input-group">
                                  <input type="text" className="form-control border-0" placeholder="Enter postcode" aria-label="enter-postcode" aria-describedby="enter-postcode" />
                                  <button className="btn btn-light" type="button" id="enter-postcode"><i className="bi bi-search"></i></button>
                                </div>
                              </div>
                              <div className="mb-3 mt-3 text-center">
                                <Link to={'/add_address'}  className="fs-18 text-muted">Or enter address manually</Link>
                              </div>
                              <div className="border-bottom-separator my-4"></div>        
                          </form>
                          <p className="m-0 fw-bold text-uppercase mb-4">Saved addresses</p>
                            <div className="d-flex"> <div className="icon-hom-add me-3"><img src="images/icon-home.svg" width="24" title="Address Home" /></div>
                                <div className="w-100">
                                  <div className="row">
                                    <div className="col-3 fw-bold">Home</div>
                                    <div className="col-6">Stephenson Close, Pink Road,<br/>London, SW9 7UT</div>
                                    <div className="col-3 text-right"><button className="btn btn-outline-black btn-edt-add" type="button"	>Edit address</button></div>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom-separator my-4"></div>
                              <div className="d-flex"> <div className="icon-wrk-add me-3"><img src="images/icon-office.svg" width="24" title="Address Home" /></div>
                                <div className="w-100">
                                  <div className="row">
                                    <div className="col-3 fw-bold">Work</div>
                                    <div className="col-6">Stephenson Close, Pink Road,<br/>London, SW9 7UT</div>
                                    <div className="col-3 text-right"><button className="btn btn-outline-black btn-edt-add" type="button"	>Edit address</button></div>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom-separator my-4"></div>
                              <div className="d-flex"> <div className="icon-fam-add me-3"><img src="images/icon-custom2.svg" width="24" title="Address Home" /></div>
                                <div className="w-100">
                                  <div className="row">
                                    <div className="col-3 fw-bold">Family</div>
                                    <div className="col-6">Stephenson Close, Pink Road,<br/>London, SW9 7UT</div>
                                    <div className="col-3 text-right"><button className="btn btn-outline-black btn-edt-add" type="button"	>Edit address</button></div>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom-separator my-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
              )
}

export default MyAddress

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CheckoutDelivery, GetMyCartData } from "../../services";

const Checkout =(props)=>{

  const[progressBar,setProgressBar] = useState(0);
  const [postCode, setPostCode] = useState("E16%201QQ");
  let userData = JSON.parse(localStorage.getItem('userDetails'));

  ////////////////\\\\\\\\\\\\\\\
  const [cartData,setCartData] = useState({});

  const [cartAmount,setCartAmount] = useState({});
  let auth_token = localStorage.getItem('userDetails');
  useEffect(()=>{
    async function getMyCart() {
     
      const res = await GetMyCartData.getMyCartData();
      // const res = await GetCategoryList.getCategoryList(sellerId);

      if (res) {
        console.log("getMyCart",res.data.data)
        setCartData(res.data.data);
        setCartAmount(res.data.data.discount)
        // await localStorage.setItem(
        //   "allCategories",
        //   JSON.stringify(res.data.data.category)
        // );
      } else {
        console.log("error");
      }
    }
    if(auth_token){
      getMyCart()
    }

  },[])
  /////////\\\\\\\\\\\\\\\

  const checkDeliveryPostcode1 = async() =>{
    // alert("ok")
    let res = await CheckoutDelivery.checkDeliveryPostcode(postCode);
    if(res){
      setProgressBar(100)
      console.log(res)

      // console.log("res" , res)
    }
  }
  const handleChange = (e)=>{
    setPostCode(e.target.value)
  }
    return(
        <div>

   {/* -------------------------Checkout--------------------------- */}
   <section class="section-breadcrumb">
  <div class="container py-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb m-0">
        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
        <li class="breadcrumb-item" aria-current="page"><Link to="/basket">Basket</Link></li>
        <li class="breadcrumb-item" aria-current="page"><Link to="/my_account">Contact details</Link></li>
        <li class="breadcrumb-item active" aria-current="page"><Link to="#">Delivery address</Link></li>
      </ol>
    </nav>
  </div>
</section>
<section className="Product-Details">
  <div className="container py-3">
    <div className="row mb-3">
      <div className="col-md-8">
        <div className="row mb-4">
          <div className="col-12">
            <div className="accordion accordion-plus" id="accordionCheckout">
              <div className="steps">
                <div className="progress" id="progress">
                  <div className="progress-bar" role="progressbar" style={{width: `${progressBar}%`, ariaValuenow:"50", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                </div>
                <div className="step-item">
                 <button className={`step-button text-center collapsed ${userData ? "done" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target="#contact-details-collapse" aria-expanded="false"> <i className="bi bi-check"></i> </button>
                  <div className="step-title"> Contact details </div>
                </div>
                <div className="step-item">
                  <button className={`step-button text-center collapsed ${progressBar >= 50 ? "done": ""}`} type="button" data-bs-toggle="collapse" data-bs-target="#delivery-address-collapse" aria-expanded="false"> <i className="bi bi-check"></i> </button>
                  <div className="step-title"> Delivery address </div>
                </div>
                <div className="step-item">
                  <button className={`step-button text-center collapsed ${progressBar === 100 ? "done": ""}`} type="button" data-bs-toggle="collapse" data-bs-target="#payment-details-collapse" aria-expanded="false"> <i className="bi bi-check"></i> </button>
                  <div className="step-title"> Payment details </div>
                </div>
              </div>
                <div className="accordion-item">
                  <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#delivery-date-collapse" aria-expanded="false"> Delivery Date </button>
                  <div className="collapse" id="delivery-date-collapse" data-bs-parent="#accordionCheckout">
                    <div className="pt-2 pb-3 list-unstyled">
                      <div className="row">
                        <div className="col-7">
                          <p className="m-0 text-muted">Delivery date</p>
                        </div>
                        <div className="col-5">
                          <h6 className="text-right m-0">
                            <button type="button" className="btn btn-link text-warning p-0" data-bs-toggle="modal" data-bs-target="#DeliveryDateModal">Wed, Aug 18 <i className="bi bi-calendar-week ms-2"></i></button>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#contact-details-collapse" aria-expanded="false"> contact details </button>
                  <div className="collapse" id="contact-details-collapse" data-bs-parent="#accordionCheckout">
                    <div className="pt-2 pb-3 gray-inout">
                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <label for="" className="form-label">Recipient Name</label>
                          {/* {JSON.stringify(userData)} */}
                          
                          <input type="text" className="form-control" value={userData.name} aria-label="First name" />
                        </div>
                        <div className="col-md-6 mb-2">
                          <label for="" className="form-label">&nbsp;</label>
                          <input type="text" className="form-control" placeholder="Last name" aria-label="Last name" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <label for="phonenumber" className="form-label">Phone number</label>
                          <div className="input-group with-bg">
                            <select className="form-select input-group-drop me-2 rounded-3" id="countrySelectDrop">
                              <option selected="">{userData.country_code}</option>
                              {/* <option value="1">+2</option>
                              <option value="2">+3</option>
                              <option value="3">+4</option> */}
                            </select>
                            <input type="text" className="form-control rounded-3" value={userData.phone} id="phonenumber" />
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <label for="exampleFormControlInput1" className="form-label">Email</label>
                          <input type="email" className="form-control" value={userData.email} id="exampleFormControlInput1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#delivery-address-collapse" aria-expanded="false"> Delivery address </button>
                  <div className="collapse" id="delivery-address-collapse" data-bs-parent="#accordionCheckout">
                    <div className="pt-2 pb-3 gray-inout">
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <label for="enter-postcode" className="form-label">Post code</label>
                          <div className="input-group">
                            <input name="postCode" type="text" onChange={(e)=>handleChange(e)} className="form-control border-0" placeholder="Enter postcode" aria-label="enter-postcode" aria-describedby="enter-postcode" value={postCode} />
                            <button onClick={checkDeliveryPostcode1} className="btn btn-light" type="button" id="enter-postcode"><i className="bi bi-search"></i></button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-2"><a href="#" className="link-warning fs-18" data-bs-toggle="collapse" data-bs-target="#manualAddressDiv">Or enter address manually</a></div>
                      </div>
                      <div id="manualAddressDiv" className="collapse">
                        <div className="row mt-2">
                          <div className="col-md-12 mt-2">
                            <label for="flat-number" className="form-label">Flat number</label>
                            <input type="text" className="form-control" placeholder="Enter flat number" aria-label="flat-number" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-2">
                            <label for="street-number" className="form-label">Street</label>
                            <input type="text" className="form-control" placeholder="Enter street number" aria-label="street-number" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-2">
                            <label for="selectCity" className="form-label">City</label>
                            <select className="form-select" id="">
                              <option selected="">Option 01</option>
                              <option value="1">Option 02</option>
                            </select>
                          </div>
                          <div className="col-md-6 mb-2">
                            <label for="selectCountry" className="form-label">Country</label>
                            <select className="form-select" id="">
                              <option selected="">Option 01</option>
                              <option value="1">Option 02</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#payment-details-collapse" aria-expanded="false"> Payment details </button>
                  <div className="collapse" id="payment-details-collapse" data-bs-parent="#accordionCheckout">
                    <div className="pt-2 pb-3 gray-inout">
                      <div className="row mb-2">
                        <div className="col-md-12 mb-2">
                          <button type="button" className="btn btn-outline-black apple-pay"><img src="images/apple-pay.svg" height="27" title="Apple Pay" /></button>
                          <button type="button" className="btn btn-outline-black visa-pay"><img src="images/visa-pay.svg" height="18" title="Visa Pay" /></button>
                          <button type="button" className="btn btn-outline-black add-pay"><i className="bi bi-plus"></i><br/>
                          Add</button>
                        </div>
                      </div>
                      <div className="row g-3">
                        <div className="col-md-8 mb-2">
                          <label for="cardnumber" className="form-label">Card number</label>
                          <input type="text" className="form-control rounded-3" value="1234 5678 9012 3456" id="cardnumber" />
                        </div>
                        <div className="col-md-2 mb-2">
                          <label for="ExpiryDate" className="form-label">Expiry Date</label>
                          <input type="text" className="form-control" placeholder="MM/YY" id="ExpiryDate" />
                        </div>
                        <div className="col-md-2 mb-2">
                          <label for="cvvno" className="form-label">CVC/CVV</label>
                          <input type="text" className="form-control" placeholder="000" id="cvvno" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <h6 className="fw-bold text-uppercase border-bottom border-2 pb-2 mb-3">order Summary</h6>
        <div className="row mb-2">
          <div className="col-7">
            <p className="m-0 text-muted">Basket total</p>
          </div>
          <div className="col-5">
            <p className="text-right m-0">£
            {cartAmount.basketItemsValue}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-7">
            <p className="m-0 text-muted">Delivery fee</p>
          </div>
          <div className="col-5">
            <p className="text-right m-0">£
            {cartAmount.deliveryCharge}
            </p>
          </div>
        </div>
        <div className="border-bottom-separator mt-2"></div>
        <div className="row mb-2">
          <div className="col-7">
            <p className="m-0 text-muted">Promo Discount</p>
          </div>
          <div className="col-5">
            <p className="text-right m-0">£ 0</p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-7">
            <h6 className="m-0 fw-bold checkout-total">  Total ({cartData.cart_count} items):</h6>
          </div>
          <div className="col-5">
            <h6 className="text-right m-0 fw-bold checkout-total">£
            {cartAmount.netAmountAfterDiscount}
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-2">
            <Link to="/orderConfirm" className={`btn btn-default text-uppercase w-100 ${progressBar != 100 ? "disabled":""}`}>
              Checkout
              </Link>
          {/* <a href="Order-Confirmed.html" className="btn btn-default text-uppercase w-100 mt-2">
            Checkout
          </a> */}
          </div>
          <div className="col-12 mb-3 text-center fs-12 text-muted-2">🔒 Magicaly secured text to make all security concerns go away.</div>
          <p className="text-center small"><a href="#" data-bs-toggle="modal" data-bs-target="#OrderNoteModal">Leave an order note</a> (sample popup)</p>
        </div>
      </div>
    </div>
  </div>
</section>

<div className="modal fade" id="DeliveryDateModal" tabindex="-1" aria-labelledby="DeliveryDateModallLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header border-0">
        <h6 className="modal-title text-uppercase fw-bold" id="DeliveryDateModalLabel">Choose your delivery date</h6>
        <button type="button" className="btn btn-link btn-sm btn-pop-close" data-bs-dismiss="modal" aria-label="Close">Close</button>
      </div>
      <div className="modal-body pb-4">
        <div id="DeliveryDateSelect"></div>
      </div>
    </div>
  </div>
</div>
<div className="modal fade" id="OrderNoteModal" tabindex="-1" aria-labelledby="OrderNoteModallLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header border-0">
        <h6 className="modal-title text-uppercase fw-bold" id="OrderNoteModalLabel">Leave an order note</h6>
        <button type="button" className="btn btn-link btn-sm btn-pop-close" data-bs-dismiss="modal" aria-label="Close">Cancell</button>
      </div>
      <div className="modal-body py-0 gray-inout">
        <textarea rows="5" className="form-control" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."></textarea>
      </div>
      <div className="modal-footer border-0">
        <button type="button" className="btn btn-dark w-100 text-uppercase" data-bs-toggle="modal" data-bs-target="#AddProductModal">SAVE</button>
      </div>
    </div>
  </div>
</div>

   {/* -------------------------Checkout--------------------------- */}

        </div>


    )
}

export default Checkout;
import React,{useState,useEffect}from 'react';
import Button from 'react-bootstrap/Button';
import {Login} from '../services';
import facebook from '../images/facebook.png';
import google from '../images/google.png';
import apple from '../images/apple.png';
import { useHistory } from 'react-router-dom';


function LoginWithPassword() {

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
    
    const [msg, setmsg] = useState("");
    const [email,setEmail] = useState(localStorage.getItem('loginEmail') ? localStorage.getItem('loginEmail') : "");
    const [password,setPassword] = useState("");
    const [device_token,setDevice_token] = useState("5c8c3cd3-734a-4526-bf80-4152574a05d7");
    const [is_social,setIs_social] = useState("no");

    const History = useHistory();

    const submitForm = async (event) =>{
        event.preventDefault();
        console.log(email);
        console.log(password);
        console.log(device_token);
        console.log(is_social);
        const res = await Login.login(email,password,device_token,is_social);
        if (res) {
            console.log("login",res)
            if(res.data.status === 1){
                localStorage.setItem('userDetails',JSON.stringify(res.data.data))
                window.location = "/";
            }else{
                setmsg(res.data.message);
            }
        } else {
            console.log("error");
        } 

    };

    return (
        <div>
            <div className="container py-5">
                <div className="row mb-3">
                <div className="col-md-4 m-auto form-grey">
                    <h1 className="mb-4 text-center migra-font text-dark">Log in</h1>
                    <div className="d-grid gap-2">
                    <button type="button" className="btn btn-dark btn btn-secondary"><img src={apple} className="mr-3" height="20" alt="" />Sign in with Apple</button>
                    <button type="button" className="btn btn-outline-blue  btn btn-light"><img src={facebook} className="mr-3" height="20" alt="" />Continue with Facebook</button>
                    <button type="button" className="btn btn-outline-black  btn btn-light"><img src={google} className="mr-3" height="20" alt="" />Continue with Google</button>
                    <p className="text-muted my-3 text-center">or continue with</p>
                    <form onSubmit={submitForm}>
                        <div className="mb-4">
                            <input type="email" className="form-control" placeholder="name@emailaddress.com" id="email" value={email} name="email" onChange={(e)=> setEmail(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <input type="password" className="form-control" placeholder="Password" id="password" name="password" onChange={(e)=> setPassword(e.target.value)} value={password}/>
                        </div>
                        <div className="mt-4">
                        <p style={{color:'red'}} className="m-0">{msg}</p>
                        <button type="submit" className="btn btn-default text-uppercase w-100">Log in</button>
                        {/* <a href="index.html" className="btn btn-default text-uppercase w-100">Log in</a> */}
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default LoginWithPassword

// import api from '../../config/ApiConfig';
import { Apis } from '../../config';
import axios from 'axios'
// import { NotificationManager } from 'react-notifications';

const register = async (email,password,device_token, country_code,phone) => {
    let data = {"email": `${email}`,"password": `${password}`,"device_token": `${device_token}`,"country_code": `${country_code}`,"phone": `${phone}`};
    try {
        let result = await axios.post(Apis.Register, data);
        if (result) {
            // NotificationManager.error(result.data.error);
            return result;
        }
        // return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default {
    register,
    
};
import React from 'react'
import HomeSetting from './HomeSetting'

function PrivacyPolicy() {
    return (
        <div>
            <div class="container py-3">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Settings</li>
    </ol>
  </nav>
</div>
<div class="container mb-4">
  <div class="row mb-4">
    <div class="col-12 page-head-with-more">
      <h2 class="heading-colored fw-bold text-uppercase m-0">SETTINGS</h2>
    </div>
  </div>
  <div class="row">
      <HomeSetting></HomeSetting>
    {/* <div class="col-md-4 hide-mobile">
        <a href="My-Orders.html" class="side-link">My Orders</a>
         <a href="My-Addresses.html" class="side-link">My addresses</a>
          <a href="My-Account.html" class="side-link">My account</a> 
          <a href="Help.html" class="side-link">Help</a>
           <a href="Terms-and-conditions.html" class="side-link">Terms and conditions</a> 
           <a href="Privacy-policy.html" class="side-link active">Privacy policy</a> 
           <a href="Login.html" class="side-link">Log out</a>
           </div> */}
    <div class="col-md-8 gray-inout">
      <div class="accordion accordion-plus" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Lorem ipsum
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body text-BlueGray text-justify pt-1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem et tortor consequat id porta. Erat nam at lectus urna duis convallis convallis tellus. Mauris a diam maecenas sed enim ut sem viverra. Nisi lacus sed viverra tellus in. Bibendum at varius vel pharetra vel. A iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Fermentum dui faucibus in ornare quam viverra orci sagittis. Adipiscing elit pellentesque habitant morbi. Egestas diam in arcu cursus euismod quis. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Tellus at urna condimentum mattis pellentesque id nibh tortor id. In egestas erat imperdiet sed euismod nisi porta lorem. Elit sed vulputate mi sit amet mauris. Mi tempus imperdiet nulla malesuada pellentesque elit eget gravida. Tincidunt dui ut ornare lectus sit amet est placerat in. Donec ac odio tempor orci dapibus. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Lorem ipsum
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body text-BlueGray text-justify pt-1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem et tortor consequat id porta. Erat nam at lectus urna duis convallis convallis tellus. Mauris a diam maecenas sed enim ut sem viverra. Nisi lacus sed viverra tellus in. Bibendum at varius vel pharetra vel. A iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Fermentum dui faucibus in ornare quam viverra orci sagittis. Adipiscing elit pellentesque habitant morbi. Egestas diam in arcu cursus euismod quis. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Tellus at urna condimentum mattis pellentesque id nibh tortor id. In egestas erat imperdiet sed euismod nisi porta lorem. Elit sed vulputate mi sit amet mauris. Mi tempus imperdiet nulla malesuada pellentesque elit eget gravida. Tincidunt dui ut ornare lectus sit amet est placerat in. Donec ac odio tempor orci dapibus. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Lorem ipsum
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body text-BlueGray text-justify pt-1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem et tortor consequat id porta. Erat nam at lectus urna duis convallis convallis tellus. Mauris a diam maecenas sed enim ut sem viverra. Nisi lacus sed viverra tellus in. Bibendum at varius vel pharetra vel. A iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Fermentum dui faucibus in ornare quam viverra orci sagittis. Adipiscing elit pellentesque habitant morbi. Egestas diam in arcu cursus euismod quis. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Tellus at urna condimentum mattis pellentesque id nibh tortor id. In egestas erat imperdiet sed euismod nisi porta lorem. Elit sed vulputate mi sit amet mauris. Mi tempus imperdiet nulla malesuada pellentesque elit eget gravida. Tincidunt dui ut ornare lectus sit amet est placerat in. Donec ac odio tempor orci dapibus. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        Lorem ipsum
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body text-BlueGray text-justify pt-1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem et tortor consequat id porta. Erat nam at lectus urna duis convallis convallis tellus. Mauris a diam maecenas sed enim ut sem viverra. Nisi lacus sed viverra tellus in. Bibendum at varius vel pharetra vel. A iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Fermentum dui faucibus in ornare quam viverra orci sagittis. Adipiscing elit pellentesque habitant morbi. Egestas diam in arcu cursus euismod quis. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Tellus at urna condimentum mattis pellentesque id nibh tortor id. In egestas erat imperdiet sed euismod nisi porta lorem. Elit sed vulputate mi sit amet mauris. Mi tempus imperdiet nulla malesuada pellentesque elit eget gravida. Tincidunt dui ut ornare lectus sit amet est placerat in. Donec ac odio tempor orci dapibus. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum.
      </div>
    </div>
  </div>
</div>
    </div>
  </div>
</div>
        </div>
    )
}

export default PrivacyPolicy

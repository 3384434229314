import api from "../../config/ApiConfig";
import { Apis } from "../../config";
import axios from "axios";
// import { NotificationManager } from 'react-notifications';

 const getProductsOfSubCategory = async (id,parent_id) => {
  let data = { "sub_cat_id": `${id}`, "parent_cat_id" : `${parent_id}`,"is_all" : "no", "seller_id" : "44"};
  try {
    //   let result = await axios.post(Apis.GetPopularProduct, data);
      let result = await api.post(Apis.GetProductsOfSubCategory, data);
      if (result) {
          // NotificationManager.error(result.data.error,//axios//api);
          return result;
      }
      // return result;
  } catch (error) {
      console.log(error);
      return null;
  }
};

export default {
    getProductsOfSubCategory
};


